<template>
  <div class="col-xl-3 px-2 d-none d-lg-block" id="filersList">
    <div class="row d-lg-none bg-white justify-content-between px-5 py-3 filter-close">
        <span class="font-weight-bolder">Filters</span>
        <a href="javascript:;" class="text-secondary" @click="hideFilter"> Close</a>
        <h4 type="button" class="btn btn-link m-0 text-right"
          @click="clearFilters()">
          Clear filters
        </h4>
    </div>
    <div class="bg-light-blue rounded-lg filter-content filter-content sticky-top">
      <div class="justify-content-between align-items-center d-lg-flex d-none rounded-lg-top py-2 bg-light-blue sticky-filter-header">
        <h3 class="ml-1 m-0 font-weight-bold">Filters</h3>
        <h4 type="button" class="btn btn-link m-0 text-right"
          @click="clearFilters()">
          Clear filters
        </h4>
      </div>
      <div class="p-3 filter-components" :key="componentKey">
        <network
          productType="sim"
          :badcredit="badcredit" @update-filter="updateFilter"
          @load="onloadNetwork"
          :key="componentKey">
        </network>
        <cost productType="sim" @update-filter="updateFilter" >
        </cost>
        <data-type @update-filter="updateFilter"></data-type>
        <minutes @update-filter="updateFilter"></minutes>
        <contract @update-filter="updateFilter"></contract>
      </div>
    </div>
    <div class="sticky-button d-lg-none bg-white justify-content-between py-2 px-3 filter-close">
      <button class="btn btn-blue w-100 text-white" @click="hideFilter">
       Show Results
      </button>
    </div>
  </div>
</template>

<script>
  import Network from '../filters/network';
  import Cost from '../filters/cost_type';
  import DataType from '../filters/data'
  import Minutes from '../filters/minute';
  import Contract from '../filters/contract';
  import {mapMutations} from 'vuex'

  export default{
    name: 'Sim_filters',
    data(){
      return{
        componentKey: 0,
      }
    },
    components: {
      Network,
      Cost,
      DataType,
      Minutes,
      Contract
    },
    // Props sent by the parent component that is false.It becomes true when prop are sent by the bad credit page
    props: [
     'badcredit'
    ],
    methods:{
      ...mapMutations([
          'setProductsList',
          'setPageIndex'
      ]),
      updateFilter(filter, value){
        this.setProductsList()
        this.setPageIndex(0)
        this.addRoute(filter, value)
        this.$emit('update-products')
      },
      clearFilters(){
        var currentPage = this.$router.currentRoute["fullPath"]
        currentPage = currentPage.split('?')[0]
        this.$router.replace(currentPage)
        this.componentKey += 1
      },
      // to add the query params alongwith the name of filter and value in the url.
      addRoute: function(filter, value){
        eval(`this.$router.push({ query: { ...this.$route.query, ${filter} : "${value}" }})`)
      },
      hideFilter() {
        document.getElementById('filersList').classList.remove("filter-on-mobile", "d-block")
        document.getElementsByTagName('body')[0].classList.remove("overflow-hidden")
      },
      // called and return the value when the netwroks are fetched successfully after applying filters conditions.
      onloadNetwork(ids) {
        this.$emit('load', ids)
      }
    }
  }
</script>
<style type="text/css" lang="scss" scoped>
  .filter-content > .div-networks{
    padding-top: 0 !important;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dedcdc;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #0569b2;
  }
  @media only screen and (min-width: 1024px) {
    .filter-content{
      top: 20px;
      box-shadow: 0 1px 2px rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
      .filter-components{
        overflow-y: scroll;
        height: calc( 100vh - 100px );
        overflow-x: hidden;
      }
    }
  }
  @media only screen and (min-width: 1024px) {
    .sticky-filter-header{
      position: relative;
      z-index: 1;
      box-shadow: 0 1px 3px rgba(60,64,67,.3), 0 0 0 0 rgba(60,64,67,.15);
      background-color: #cfe7fc !important;
    }
  }
</style>
