<template>
  <div class="div-minutes pt-4">
    <h5 class="pb-2 m-0">Minutes</h5>
    <div class="w-100 row m-0 align-self-center justify-content-center">
      <div class="position-relative mt-2 mt-md-0 input-div3" v-for="(value, name) in this.minutes" :key="name">
        <input
        name="minutes"
        class="opacity-0 minutes cursor-p form-control position-absolute input-tag-radio3"
        :id="`minutes-${name}`"
        type="checkbox"
        :value="name"
        v-model="checkedMinute"
        v-on:change="setFilter"
        >
        <label :for="`minutes-${name}`" class=" input-radio3 rounded py-2 mx-auto cursor-p">
          {{value}}
        </label>
      </div>
    </div>
  </div>
</template>
<script>
  export default{
    name: "MinutesFilter",
    data: function () {
      return {
        checkedMinute: this.$route.query.minutes ? this.$route.query.minutes.split(",") : [],
        minutes: {
          500: "500",
          1000: "1000",
          1500: "1500",
          'unlimited': 'Unlimited'
        }
      }
    },
    methods: {

      setFilter(event) {
        var checkboxes = document.getElementsByName('minutes')
        checkboxes.forEach((item) => {
            if (item !== event.target) {
              item.checked = false
            }else{
               this.checkedMinute = event.target.checked ? [event.target.value] : []
            }
        })
        this.$emit('update-filter', 'minutes', this.checkedMinute);
      }
    }
  }
</script>
