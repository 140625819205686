<template>
  <div id="spinner">
    <div v-if="loading" class="spinner-container">
      <scale-loader :loading="loading" :color="'#0469b2'"></scale-loader>
    </div>
  </div>
</template>

<script>
import ScaleLoader from 'vue-spinner/src/ScaleLoader'

export default {
  name: 'Spinner',
  props: ['loading'],
  components: {
    ScaleLoader
  }
}
</script>

<style lang="scss" scoped>
  #spinner{
    .spinner-container{
      z-index: 9999;
      position: fixed;
      background: rgba(255,255,255,0.6);
      height: 100vh;
      width: 100%;
    }
    .v-spinner{
      left: 50%;
      top: 50%;
      position: fixed;
      transform: translate(-50%, -50%);
    }
  }
</style>
