<template>
  <div class="mobile-page">
    <div class="header-section">
      <div class="bg-light-blue py-5 mobiles-hero">
        <div class="container position-relative">
          <div class="text-line home-mobile-animated-background mb-3 w-m-50 w-sm-100"></div>
          <div class="position-absolute w-m-50 w-sm-100">
            <div class="text-line"></div>
          </div>
        </div>
      </div>
      <div class="container py-4">
        <div class="row">
          <div class="col-md-6">
            <div class="border p-2">
              <div class="image"></div>
              <div class="text-line mt-2"></div>
            </div>
          </div>
          <div class="col-md-6 text-center mt-md-0 mt-3">
            <div class="border p-2">
              <div class="image"></div>
              <div class="text-line mt-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="bg-light-blue pt-3 pt-md-5 pb-5">
      <div class="container">
        <div class="text-line mb-3"></div>
        <div class="row">
          <div class="col-lg-2 col-6 text-center px-2 cursor-p mb-2" v-for="product in topProducts">
            <div class="shadow-box bg-white p-3">
              <div class="mob-image"></div>
              <div class="text-line mt-2"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'mobile_page_loader',
    data(){
      return{
        topProducts: ['1','2','3','4','5','6'],
      }
    },
  }
</script>

<style lang="scss" scoped>

  .text-line {
    height: 30px;
  }

  .image {
    height: 270px;
  }
  .mob-image{
    height: 230px;
  }
</style>
