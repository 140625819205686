import http from "../components/common/http";
import Vue from "vue/dist/vue.esm";
import Vuex from "vuex";
import router from "../router";
import _ from "underscore";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    productsList: [],
    packageList: {},
    variationList: [],
    pageIndex: 0,
    spinner: true,
    loader: true,
    index: null,
  },

  mutations: {
    setPageIndex: (state, pageIndex) => {
      state.pageIndex = pageIndex;
    },
    setSpinner: (state, spinner) => {
      state.spinner = spinner;
    },
    setProductsList: (state, productsList = []) => {
      state.productsList = productsList;
    },
    setPackagesList: (state, payload) => {
      state.packageList =  payload.packages;
    },
    setLoader: (state, loader) => {
      state.loader = loader;
    },
    setVariationList: (state, variationList) => {
      state.variationList = variationList;
    },
  },

  getters: {
    pageIndex: (state) => state.pageIndex,
    productsList: (state) => state.productsList,
    spinner: (state) => state.spinner,
    loader: (state) => state.loader,
    packageList: (state) => state.packageList,
    variationList: (state) => state.variationList
  },

  actions: {
    fetchProducts(context, loaderState = null) {
      console.log("loaderState");
      context.commit("setSpinner", true);
      context.commit("setLoader", true);
      var query = _.clone(router.currentRoute.query, true);
      var dealType = router.currentRoute.params.deal_type;
      var networks = router.currentRoute.params.networks;
      if (!_.isUndefined(dealType)) {
        query = { ...query, deal_type: dealType };
      }
      if (!_.isUndefined(networks)) {
        query = { ...query, networks: networks };
      }
      query.query_offset = context.state.pageIndex;
      http
        .get(`/packages.json`, { params: query })
        .then((res) => {
          context.commit("setSpinner", false);
          context.commit("setLoader", false);
          if (loaderState) {
            loaderState.loaded();
          }
          if (res.data.packages_hash.length > 0) {
            var productList = [];
            productList = context.state.productsList;
            productList.push(...res.data.packages_hash);
            context.commit("setProductsList", productList);
            context.commit("setVariationList", res.data.packages_variation);
          } else {
            if (loaderState) {
              loaderState.complete();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchPackages(context) {
      var query = _.clone(router.currentRoute.query, true);
      var dealType = router.currentRoute.params.deal_type;
      if (!_.isUndefined(dealType)) {
        query = { ...query, deal_type: dealType };
      }
      query = { ...query, compare: true};
      http.get(`/packages/comparison_data.json`, { params: query })
        .then((res) => {
          let payload = {
            packages: res.data,
          };
          context.commit("setPackagesList", payload );
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
});
