<template>
  <div>
    <div class="d-flex network-icons border-bottom shadow-sm" @scroll="dataTableScroll">
      <div
        class="col-md-3 col-6 text-center bg-white px-0 py-2 border-right"
        v-for= "network in Object.keys(packageList[model])"
        :key="network.id"
      >
        <img :src="require(`images/logos/${network.split(' ').join('')}.png`)"
          height="50px" width="auto" class="network-img">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters} from 'vuex';

export default {
  props: [
    'model',
  ],
  computed:{
    ...mapGetters([
      'packageList'
    ]),
  },
  created(){
    this.hideTab()
  },
  methods: {
    dataTableScroll(){
      $(".network-icons").on("scroll", function () {
        $(".table-scroll").scrollLeft($(this).scrollLeft());
      });
    },
    hideTab(){
        var networkRow = document.getElementsByClassName("sticky-nav")
        if (networkRow.length > 1) {
          networkRow[1].classList.add("d-none");
        }
    },
  }
}
</script>

<style type="text/css" lang="scss" scoped>
  .network-icons{
    overflow-x : auto;
    top : 90px !important;
    &::-webkit-scrollbar
    {
      height: 5px;
      background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #eff7ff;
    }
  }
</style>