<template>
  <div class="row w-100 m-0 align-items-center py-2 py-md-4">
    <div class="col-md-6 px-2 pb-2 pb-md-4 pb-md-0">
      <h3 class="m-0 font-weight-bold text-blue text-center text-md-left fs-md-24">Contract & Pay Monthly {{this.product_type}} Deals</h3>
    </div>
    <div class="col-md-6 px-2 text-right">
      <div class="row w-100 m-0 justify-content-end">
        <div class="col-6 px-2 d-block d-lg-none">
          <button class="btn btn-filter w-100 py-2 fs-sm-13" @click.stop="showFilter">Filter
          </button>
        </div>
        <div class="col-6 px-md-0 px-2">
          <select class="custom-select select-box fs-sm-13 cursor-p overflow-hidden" v-model="selected" v-on:change="setFilter">
            <option v-for="option in options" :value="option.value">
               {{ option.text }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapMutations} from 'vuex'
  import DOM from 'dom/manipulator.js'
  export default{
    name: "SortFilter",
    data: function(){
      return{
        selected: this.$route.query.sort ? this.$route.query.sort : null,
        options: [
          { text: 'Most Popular', value: null },
          { text: 'Monthly Cost (Low to High)', value: 'cost-ASC' },
          { text: 'Monthly Cost (High to Low)', value: 'cost-DESC' },
          { text: 'Data (Low to High)', value: 'data-ASC' },
          { text: 'Data (High to Low)', value: 'data-DESC' },
          { text: 'Upfront Cost (Low to High)', value: 'upfront-ASC' },
          { text: 'upfront Cost (High to Low)', value: 'upfront-DESC' },
        ]
      }
    },
    props: [
     'product_type'
    ],
    methods: {
       ...mapMutations([
          'setProductsList',
          'setPageIndex'
      ]),
      setFilter() {
        this.setProductsList()
        this.setPageIndex(0)
        this.$router.push({ query: { ...this.$route.query, sort: this.selected }})
        this.$emit('update-products')
      },
      showFilter() {
        document.getElementById('filersList').classList.add("filter-on-mobile", "d-block")
        document.getElementsByTagName('body')[0].classList.add("overflow-hidden")
      }
    }
  }
</script>
