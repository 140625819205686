<template>
  <div class="bg-light-blue">
    <section id="section1">
      <div class="container py-5">
        <div class="row align-items-center">
          <div class="col-md-7 order-2 order-md-1">
            <div class="d-none d-md-block">
              <div class="text-line home-mobile-animated-background"></div>
              <div class="text-line home-mobile-animated-background mt-2"></div>
            </div>
            <div class="row w-100 mt-5 ml-0">
              <div class="col-lg-6 col-xl-5 pr-0 mr-2 pl-0 mb-3">
                <div class="px-3 cursor-p">
                  <div class="buttons home-mobile-animated-background"></div>
                </div>
              </div>
              <div class="col-lg-6 col-xl-5 pr-0 mr-2 pl-0">
                <div class="px-3 cursor-p">
                  <div class="buttons home-mobile-animated-background"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5 order-1 order-md-2 text-center">
            <div class="d-block d-md-none">
              <div class="text-line home-mobile-animated-background"></div>
              <div class="text-line home-mobile-animated-background mt-2"></div>
            </div>
            <div class="image home-mobile-animated-background mt-2"></div>
          </div>
        </div>
      </div>
    </section>
    <section id="section2">
      <div class="offer home-mobile-animated-background"></div>
      <div class="justify-content-center py-2 d-flex flex-wrap">
        <div class="m-3 logos home-mobile-animated-background"></div>
        <div class="m-3 logos home-mobile-animated-background"></div>
        <div class="m-3 logos home-mobile-animated-background"></div>
        <div class="m-3 logos home-mobile-animated-background"></div>
        <div class="m-3 logos home-mobile-animated-background"></div>
        <div class="m-3 logos home-mobile-animated-background"></div>
        <div class="m-3 logos home-mobile-animated-background"></div>
        <div class="m-3 logos home-mobile-animated-background"></div>
        <div class="m-3 logos home-mobile-animated-background" ></div>
      </div>
    </section>
    <section id="section3">
      <div class="container py-4">
        <div class="row align-items-center">
          <div class="col-md-6 order-2 order-md-1">
            <div class="text-line home-mobile-animated-background mb-5"></div>
            <div class="buttons home-mobile-animated-background m-1"></div>
            <div class="buttons home-mobile-animated-background m-1"></div>
            <div class="buttons home-mobile-animated-background m-1"></div>
          </div>
          <div class="col-md-6 text-center order-1 order-md-2">
            <div class="text-line home-mobile-animated-background"></div>
            <div class="image home-mobile-animated-background"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  export default{
    name: 'home_page_loader',
    data: function() {
      return {
        dealBtns: [
        ]
      }
    },
  }
</script>

<style lang="scss" scoped>
  .text-line {
    height: 30px;
  }
  .image {
    height: 300px;
  }
  .buttons{
    height: 80px;
  }
  .offer{
    height: 90px;
  }
  .logos{
    height: 50px;
    width: 50px;
  }
</style>

