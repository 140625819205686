<template>
  <div class="container product-show pb-5" >
    <loader :loading = "loader" />
    <div v-if="!loader">
      <show-sim v-if="product.type == 'sim'" :product='product' />
      <show-other v-else :product='product' @get-package="getPackage"/>
    </div>
  </div>
</template>
<script>
  import http from '../../common/http';
  import ShowSim from './components/show_sim_packages';
  import ShowOther from './components/show_other_packages';
  import {mapMutations, mapGetters} from 'vuex'
  import Spinner from '../../common/spinner'
  import Loader from '../../common/show_page_loader'
  export default {
    name: 'ShowProduct',
    components: {
      Loader,
      Spinner,
      ShowSim,
      ShowOther,
    },
    data() {
      return{
        product: {},
      }
    },
    mounted(){
      this.getPackage()
    },
    computed: {
      ...mapGetters([
        'spinner',
        'loader'
      ]),
    },
    methods:{
      getPackage() {
        http.get(`/packages/${this.$route.params.id}.json`, {params: this.$route.query} )
          .then(res => {
            this.product = res.data
            this.setSpinner(false)
            this.setLoader(false)
          })
          .catch(err => {
            console.log(err)
          })
      },
      ...mapMutations([
        'setSpinner',
        'setLoader'
      ]),
    }
  }
</script>
