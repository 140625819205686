<template>
  <div class="div-merchants pt-4">
    <h5 class="pb-2 m-0">Merchants</h5>
    <div class="w-100 row m-0 align-self-center justify-content-center">
      <div class="position-relative mt-2 mt-md-0 input-div2" v-for="merchant in this.merchants" :key="merchant.id">
        <input
        name="merchant"
        class="opacity-0 merchant cursor-p form-control position-absolute input-tag-radio2"
        :id="`merchant-${merchant.name.split(' ').join('')}`"
        type="checkbox"
        :value="merchant.id"
        v-model="checkedMerchant"
        v-on:change="setFilter"
        >
        <label :for="`brand-${merchant.name.split(' ').join('')}`" :class="`${merchant.name.split(' ').join('')}-img bg-img-position input-radio2 rounded py-2 mx-auto cursor-p`">
        </label>
      </div>
    </div>
  </div>
</template>

<script>
  import http from '../../common/http'
  export default{
    name: "merchantFilter",
    props: ['productType'],
    data: function(){
      return{
        merchants: [],
        checkedMerchant: this.$route.query.merchants ? this.$route.query.merchants.split(",") : [],
      }
    },
    mounted(){
      this.getMerchants()
    },
    methods:{
      setFilter() {
        this.$emit('update-filter','merchants', this.checkedMerchant);
      },
      getMerchants: function() {
        http.get('/merchants.json', {params: {type: this.productType, model_name: this.$router.currentRoute.query.model_name}})
          .then( res => {
            this.merchants = res.data
          }).catch(err => {
            console.log(err)
          })
      },
    }
  }
</script>
