<template>
  <div class="sim_no_credits pb-5">
    <div class="container mt-2 mt-md-5 px-0">
      <sort-filter :product_type='"Sim"' @update-products="updateProducts"></sort-filter>
      <div class="row w-100 m-0">
        <sim-filters :badcredit='true' @update-products="updateProducts" @load="onloadNetwork"></sim-filters>
        <sim-deals v-if="success" @update-products="updateProducts"></sim-deals>
      </div>
    </div>
  </div>
</template>
<script>
  import SortFilter from '../filters/sort_filter';
  import SimFilters from './simfilters';
  import SimDeals from './sim_deals'
  import {mapMutations, mapGetters} from 'vuex';
  export default{
    name: 'Sims',
    data: function(){
      return{
        success: false,
        networkIds: [],
        dup: [],
      }
    },
    components: {
      SortFilter,
      SimFilters,
      SimDeals
    },
    created(){
      this.setProductsList([])
      this.setPageIndex(0)
    },
    methods:{
      ...mapMutations([
        'setProductsList',
        'setPageIndex',
      ]),
      // this function is called when its child components set the filters and respond with some values. These conditions are set to show the packages of the badcredit networks.
      updateProducts(_filters_load,loaderState = null){
        this.$route.params.deal_type = 'sim'
        if(this.networkIds != null){
          for(var i of this.networkIds){
            this.dup.push(i);
          }
          this.$route.params.networks = this.networkIds.join(',')
        }else if(this.$route.query.networks==""){
          if(this.$route.query.networks.length == '0'){
            this.$route.params.networks = this.dup.join(',')
          }
        }else if(this.$route.query.networks== null){
          this.$route.params.networks = this.dup.join(',')
        }
        this.$store.dispatch('fetchProducts', loaderState)
        this.networkIds=null;

      },
      // called when the netwroks are successfully fetched, main purpose of this function is to get the packages after getting the information about the type of user who is accessing our site. If badcredit customer is accessing our site than only few networks packages will b visible to him.
      onloadNetwork(ids) {
        this.networkIds = ids
        this.success = true
      }
    },
  }
</script>
