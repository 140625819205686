<template>
  <div>
    <div v-for="count in this.deals" class="mb-2">
      <div class="deal-div bg-white position-relative p-1 mb-4 py-md-1 py-3 border">
        <div class="row w-100 m-0 align-items-center">
          <div class="col-md-2 col-4 border-right text-center br-md-none">
            <div class="mob-image animated-background">
            </div>
          </div>
          <div class="col-8 d-md-none d-block">
            <div class="m-0 text-line animated-background"></div>
            <div class="m-0 mt-1 text-line animated-background"></div>
            <div class="d-flex text-center d-md-none d-block w-100 py-3 border mt-1">
              <div class="col-6 border-right text-center align-self-center">
                <div class="m-0 text-line animated-background"></div>
                <div class="m-0 text-line animated-background"></div>
              </div>
              <div class="col-6 text-center align-self-center">
                <div class="text-line animated-background m-0"></div>
                <div class="text-line animated-background m-0"></div>
              </div>
            </div>
          </div>
          <div class="col-md-6 px-md-2 px-0 align-self-end mb-2">
            <div class="text-md-left text-center py-3 d-none d-md-block">
              <div class="text-line animated-background"></div>
              <div class="text-line animated-background mt-1"></div>
            </div>
            <div class="row w-100 m-0 py-3 py-md-0">
              <div class="col-4 border-right text-center d-flex justify-content-center bonus">
                <div class="mx-auto animated-background"></div>
              </div>
              <div class="col-4 border-right text-center d-flex justify-content-center bonus">
                <div class="mx-auto animated-background"></div>
              </div>
              <div class="col-4 border-right text-center d-flex justify-content-center bonus">
                <div class="mx-auto animated-background"></div>
              </div>
            </div>
          </div>
          <div class="col-md-2 text-center align-self-end border-right d-md-block d-none pb-5">
            <div class="text-line animated-background"></div>
            <div class="text-line animated-background mt-1"></div>
          </div>
          <div class="col-md-2 text-center align-self-end py-md-2 px-md-2">
            <div class="text-line animated-background m-0 d-none d-md-block lh-1"></div>
            <div class="text-line animated-background m-0 d-none d-md-block lh-1"></div>
            <div class="text-line animated-background px-lg-4 px-md-2 px-5 my-md-2 mt-2 w-100"></div>
            <div class="text-line animated-background mx-auto d-none d-md-block"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
  export default {
    name: 'dealsLoader',
    props: ['loading'],

    data:function(){
      return{
        deals: ['1', '2', '3']
      }
    }
  }
</script>

<style lang="scss" scoped >
  .text-line {
    height: 20px;
  }
  .mob-image animated-background {
    height: 150px;
    width: 100px;
  }
  .bonus{
    height: 106px;
    div {
      height: 100%;
      width: 106px;
      border-radius: 50%;
    }
  }
</style>
