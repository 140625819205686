// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start();
// require("@rails/activestorage").start();
// require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "es6-promise/auto";
import "bootstrap/dist/css/bootstrap.css";
import Vue from "vue/dist/vue.esm";
import VueRouter from "vue-router";
import router from "./router";
import store from "./store/package_store";
import App from "./components/packages/app";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueAgile from 'vue-agile'
import VShowSlide from "v-show-slide";
Vue.use(VShowSlide);
Vue.use(VueAgile)
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.component("app", App);

document.addEventListener("DOMContentLoaded", () => {
  new Vue({
    el: '[data-behavior="vue"]',
    router,
    store,
    components: { App },
  });
});
