<template>
  <div class="row justify-content-center">
    <div class="position-relative mt-2 mt-md-0 col-6 px-1">
      <input
      name="deals-type"
      class="opacity-0 deals-type cursor-p form-control position-absolute input-tag-radio"
      id="phone-deals"
      type="radio"
      value="phone"
      v-model="dealType"
      v-on:change="setFilter"
      >
      <label for="phone-deals" class="input-radio rounded p-2 mx-auto cursor-p">
        Phone deals
      </label>
    </div>
    <div class="position-relative mt-2 mt-md-0 col-6 px-1">
      <input
      name="deals-type"
      class="opacity-0 deals-type cursor-p form-control position-absolute input-tag-radio"
      id="sim-deals"
      type="radio"
      value="sim"
      v-model="dealType"
      v-on:change="setFilter"
      >
      <label for="sim-deals" class="input-radio rounded p-2 mx-auto cursor-p">
        SIM deals
      </label>
    </div>
    <div class="position-relative mt-2 mt-md-0 col-6 px-1">
      <input
      name="deals-type"
      class="opacity-0 deals-type cursor-p form-control position-absolute input-tag-radio"
      id="tablet-deals"
      type="radio"
      value="tablet"
      v-model="dealType"
      v-on:change="setFilter"
      >
      <label for="tablet-deals" class="input-radio rounded p-2 mx-auto cursor-p">
        Tablet deals
      </label>
    </div>
  </div>
</template>
<script>
export default{
  name: "CostFilter",
  data: function () {
    return {
      dealType: this.$route.query.deal_type,
    }
  },
  methods: {
    setFilter() {
      this.$emit('update-filter', 'deal_type', this.dealType);
    },
  }
}
</script>
