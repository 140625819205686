<template>
<div>
    <footer class="bg-light-blue w-100 mx-0 pt-3"  v-if="$route.path != '/'">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-lg-12 col-12 text-left">
            <p class="small">Information Commissioner’s Office (ICO) registration number ZB029467</p>
            <p class="small">
              SWITCH ONLINE LTD, A UK limited company registered in England and Wales.
              Registered: Cedrus A Brookfield Lane West, Cheshunt, Waltham Cross, England, EN8 0QY.
              Registration Number: 13231861.
            </p>
            <p class="small">*Switchuk.com does not perform a credit check but you may be asked to complete one if you apply for a mobile phone contract with our partners.</p>
          </div>
        </div>
      </div>
      <div class="bg-blue row w-100 m-0">
        <div class="col-md-6">
          <p class="m-0 py-3 text-center text-white">Copyrights©{{ new Date().getFullYear() }} SwitchUK All Rights Reserved.</p>
        </div>
        <div class="col-md-6">
          <p class="py-3 text-center m-0 text-white">
            <a href="" @click.stop="$router.push({path: 'cookie_policy'})"  class="mx-md-2 text-white">Cookie Policy</a> |
            <a href="" @click.stop="$router.push({path: '/privacy_policy'})" class="mx-md-2 text-white"> Privacy Policy </a> |
            <a href="" @click.stop="$router.push({path: 'terms_and_conditions'})"  class="mx-md-2 text-white">Terms and conditions</a>
          </p>
        </div>
      </div>
    </footer>
    <footer class="bg-light-purp w-100 mx-0 pt-3"  v-if="$route.path === '/'">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-lg-12 col-12 text-left">
            <p class="small">Information Commissioner’s Office (ICO) registration number ZB029467</p>
            <p class="small">
              SWITCH ONLINE LTD, A UK limited company registered in England and Wales.
              Registered: Cedrus A Brookfield Lane West, Cheshunt, Waltham Cross, England, EN8 0QY.
              Registration Number: 13231861.
            </p>
            <p class="small">*Switchuk.com does not perform a credit check but you may be asked to complete one if you apply for a mobile phone contract with our partners.</p>
          </div>
        </div>
      </div>
      <div class="bg-orng row w-100 m-0">
        <div class="col-md-6">
          <p class="m-0 py-3 text-center text-white">Copyrights©{{ new Date().getFullYear() }} SwitchUK All Rights Reserved.</p>
        </div>
        <div class="col-md-6">
          <p class="py-3 text-center m-0 text-white">
            <a href="" @click.stop="$router.push({path: 'cookie_policy'})"  class="mx-md-2 text-white">Cookie Policy</a> |
            <a href="" @click.stop="$router.push({path: '/privacy_policy'})" class="mx-md-2 text-white"> Privacy Policy </a> |
            <a href="" @click.stop="$router.push({path: 'terms_and_conditions'})"  class="mx-md-2 text-white">Terms and conditions</a>
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  export default{
    name: "Footer"
  }
</script>
<style type="text/css" scoped>
  #cookie{
    margin-bottom:0rem ;
  }
</style>
