<template>
  <div id="compare-page">
    <div id="filter-section" class="bg-light-blue">
      <CompareFilters
        @show-data="showTable"
        @update-products="updateProducts"
        @phone-name="getNameAndImage"
        :products="allProducts"
        :options="options"
      />
    </div>
    <div class="container px-0" v-if="show">
      <div id="mobile-tabs" class="row align-items-end w-100 mx-0 p-0">
        <div class="text-md-right text-center col-12 py-3 px-md-0">
          <button type="button" class="btn btn-blue text-white" data-toggle="modal" data-target="#filterModal">
              Apply Filters
          </button>
        </div>
        <div
          v-for="(packages, selectedPhoneName, index) in packageList"
          class="col-6 p-0 rounded"
          :key="selectedPhoneName"
          :data-name='selectedPhoneName'
          @click="toggleTabs()"
        >
          <div v-if="Object.keys(packageList) != ''">
            <div v-if="Object.keys(packages).length != 0">
              <div
                style="height: 200px"
                class="selected-models w-100 py-4 text-center mx-auto model-img"
                :data-name="selectedPhoneName"
              >
                <img class="thumb" :src="phoneImage[index]" style="object-fit: contain;"/>
              </div>
              <button
                type="button"
                :data-name="selectedPhoneName"
                class="btn button bg-light-blue p-2 w-100 table-tabs rounded-0"
              >
                {{ phoneName[index] }}
              </button>
            </div>
            <div v-else>
              <div
                style="height: 200px"
                class="selected-models w-100 py-4 text-center mx-auto model-img"
                :data-name="selectedPhoneName"
              >
                <img class="thumb" :src="require('images/empty-phone.png')" style="object-fit: contain;"/>
              </div>
              <button
                type="button"
                :data-name="selectedPhoneName"
                class="btn button bg-light-blue p-2 w-100 table-tabs rounded-0"
              >
                {{ phoneName[index] }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- SweetAlert Icon Component if no phone is selected - Start -->
      <div v-if="phoneNotSelected">
        <div class="mt-5" id="sweetAlret">
          <div class="text-center">
            <div class="circle-wrapper">
              <div class="warning circle"></div>
              <div class="icon">
                <i class="fa fa-exclamation"></i>
              </div>
            </div>
            <p>Please select the Handsets to View the Deals.</p>
          </div>
          <div class="position-absolute point-arrow d-none d-md-block">
            <img :src="require('images/arrow.png')">
          </div>
        </div>
      </div>
      <!-- SweetAlert Icon Component if no phone is selected - End -->
    </div>
    <!-- Sticky Header Nav Component - Start -->
    <div class="stickyHeader animate__animated animate__fadeOutUp">
      <div id="navigation-tabs" v-if="Object.keys(packageList).length != 0">
        <div v-if="show" class="container px-0 shadow-sm bg-light-blue">
          <div id="small-tabs" class="row w-100 align-items-end mx-0 p-0">
            <div
              v-for="(packages,selectedPhoneName, index) in packageList"
              class="bg-light-blue col-6 p-0"
              :key="selectedPhoneName.id"
            >
              <div v-if="Object.keys(packages).length != 0">
                <button
                  type="button"
                  :data-name="selectedPhoneName"
                  class="btn button d-flex justify-content-center w-100 align-items-center
                        p-2 sticky-tabs rounded-0"
                  @click="toggleTabs()"
                >
                  <div style="height: 55px; width: 55px">
                    <img class="thumb" :src="phoneImage[index]" style="object-fit: contain;"/>
                  </div>
                  <span>{{ phoneName[index] }}</span>
                </button>
              </div>
              <div v-else>
                <button
                  type="button"
                  :data-name="selectedPhoneName"
                  class="btn button d-flex justify-content-center w-100 align-items-center
                        p-2 sticky-tabs rounded-0"
                  @click="toggleTabs()"
                >
                  <div style="height: 55px; width: 55px">
                    <img
                      class="thumb"
                      :src="require('images/empty-phone.png')"
                      style="object-fit: contain;"
                    />
                  </div>
                  <span>{{ phoneName[index] }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container bg-light shadow px-0">
        <div v-if="show">
          <div
            v-for="(packages,index) in Object.keys(packageList)"
            :key="index"
            :id="packages"
            class="sticky-nav data-tabs"
          >
            <NetworkIcons :model="packages" />
          </div>
        </div>
      </div>
    </div>
    <!-- Sticky Header Nav Component - End -->

    <div id="tab-layout">
      <!-- Modal Component - Start -->
      <div class="modal fade" id="filterModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered mx-auto">
          <div class="modal-content border-0 bg-transparent">
            <div class="modal-body p-0">
              <CompareFilters
                :key="componentKey"
                @update-products="updateProducts"
                :products="allProducts"
                :options="options"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Modal Component - End -->
      <div class="container bg-light shadow px-0">
        <div v-if="show">
          <div
            v-for="(packages,index) in Object.keys(packageList)"
            :key="index"
            :id="packages"
            class="data-tabs data-table d-none"
          >
            <NetworkIcons :model='packages'/>
            <DataTable
              v-if="Object.keys(packageList).length > 0"
              :packages="packageList[packages]"
            />
            <div class="text-center" v-else>
              <img :src="require('images/no-data.png')" class="col-lg-4 col-md-6 col-sm-8 px-0">
              <p class="pb-3">{{packages}} with such filters not available.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DataTable from '../compare_deals/data_table'
  import NetworkIcons from '../../shared/network_icons'
  import CompareFilters from '../compare_deals/compare_filters'
  import {mapGetters} from 'vuex';
  import http from '../../common/http'
  import _ from 'lodash';


  export default{
    name:"compare-deals",
    computed:{
      ...mapGetters([
        'packageList',
      ]),
    },
    data(){
      return{
        show: false,
        componentKey: 0,
        allProducts: [],
        options: [],
        phoneNotSelected: true,
        phoneName: [],
        phoneImage: [],
      }
    },
    created(){
      this.fetchMobile();
    },
    // This is commented intentionally for later work
    // beforeRouteEnter(){
    //   if(from.name == "Show Product"){
    //     this.show = true;
    //   }
    //   next();
    // },

    components:{
      DataTable,
      CompareFilters,
      NetworkIcons
    },

    mounted(){
       window.addEventListener('scroll', this.handleScroll);
    },
    methods:{
      /* Method for Sticky Header Nav component - Start */
      handleScroll() {
        var header = document.getElementById("masthead");
        var mobileTabs = document.getElementById("mobile-tabs");
        if(mobileTabs){
          if ( window.scrollY > ( mobileTabs.offsetHeight + header.offsetHeight)) {
            $('.stickyHeader').addClass('animate__fadeInDown');
            $('.stickyHeader').removeClass('animate__fadeOutUp');
            $('.stickyHeader').addClass('animate__animated');
          }
          else{
            $('.stickyHeader').addClass('animate__fadeOutUp');
          }
        }
      },
      /* Method for Sticky Header Nav component - End */

      /* Method for Fetching Phones for displaying on Compare Deals Page Selectbox - Start */
      fetchMobile(){
        http
          .get(`/compare_phones.json`)
          .then((res) => {
            this.allProducts = res.data
            this.options = Object.keys(res.data)
          })
          .catch((err) => {
            console.log(err);
          });
      },
      /* Method for Fetching Phones for displaying on Compare Deals Page Selectbox - End */

      /* Method for displaying the same name and image on buttons and sticky nav - Start
      It gets the emitted hash from compare filter and converts it into array. */
      getNameAndImage(value){
        for(var i=0; i < Object.keys(value).length; i++){
          this.phoneName[i] = value[Object.keys(value)[i]];
          if(this.phoneName[i] != ""){
            this.phoneImage[i] = this.allProducts[this.phoneName[i]][0].avatar
          }
        }
      },
      /* Method for displaying the same name and image on buttons and sticky nav - End */

      toggleTabs(){
        var dataTables = document.getElementsByClassName("data-tabs");
        var selectedModels = document.getElementsByClassName("selected-models");
        var stickyTabs = document.getElementsByClassName("sticky-tabs");
        var modelName = event.currentTarget.getAttribute("data-name");
        _.forEach(dataTables,function(tab){
          if (tab.getAttribute("id") == modelName) {
              tab.classList.remove("d-none");
          }
          else{
            tab.classList.add("d-none");
          }
        })
        _.forEach(selectedModels,function(device){
          if (device.getAttribute("data-name") == modelName) {
              device.classList.add("shadow-custom");
              device.classList.add("active-model");
          }
          else{
            device.classList.remove("active-model");
            device.classList.remove("shadow-custom");
          }
        })
        _.forEach(stickyTabs,function(stickyTab){
          stickyTab.classList.remove("active");
          if (stickyTab.getAttribute("data-name") == modelName) {
              stickyTab.classList.add("active");
          }
        })
        this.showActive(modelName)
      },
      showActive(modelName){
        var navTabs = document.getElementsByClassName("table-tabs");
        _.forEach(navTabs, function(tab){
          if (tab.getAttribute("data-name") == modelName ) {
            tab.classList.add("active");
          }
          else{
            tab.classList.remove("active");
          }
        })
      },
      showTable(value){
        this.show = value
      },
      updateProducts(filters_load = null){
        if (filters_load == true) {
          this.componentKey += 1
        }
        this.$store.dispatch('fetchPackages')
        if(this.$route.query["model_one"] && this.$route.query["model_two"]){
          this.phoneNotSelected=false
        }
      },
    }
  }
</script>
<style type="text/css" lang="scss" scoped>
  #filterModal{
    .modal-dialog{
      max-width:90%;
    }
  }
  .table-tabs, .sticky-tabs{
    border: 1px solid #eff7ff;
    height: 90px;
    &:hover{
      border-top-color: #aaa;
    }
    &:focus{
      box-shadow: none !important;
    }
  }
  #small-tabs{
    .btn{
      border-bottom: 1px solid #0469b2;
      color: #6c757d;
    }
    .active{
      color:#000;
      border-bottom-color: transparent;
      border-top-color: #0469b2;
      border-right-color: #0469b2;
      border-left-color: #0469b2;
    }
  }
  #mobile-tabs{
    transition: all 0.3s ease-in-out;
    .selected-models{
      .model-img{
        height:200px;
      }
    }
  }
  .selected-models{
    &:hover{
      box-shadow: 0 1px 5px rgba(60, 64, 67, 0.3), 0 1px 3px 3px rgba(60, 64, 67, 0.15) !important;
    }
  }
  .active-model{
    img{
      transform: scale(1.05);
    }
  }

  // Styling for arrow position on copmare deals page in case if no phone is selected

  .point-arrow{
    top: 120px;
    right: 90px;
  }

  // Styling for Sticky Header Nav Component

  .stickyHeader{
    position: fixed;
    width: 100%;
    opacity: 0;
    top: 0;
    z-index: 1000;
  }

</style>
