<template>
  <div class="bg-white w-100 p-md-5 container">
    <div class="text-center">
      <p class="font-weight-bold h4">Welcome to the Switch Online Ltd (trading as switchuk.com) Privacy Policy</p>
    </div>
    <div>
      <p>This information has been produced to help you understand everything you need to know about the way switchuk.com collects, uses and shares personal data, what your legal rights are and how to exercise them.
      </p>
      <p>
      We hope you’ll take some time to read this document; we’ve tried to keep it all as simple as possible and to avoid jargon, and we’ll make our best efforts to keep you informed if there are any changes to the way we process your personal data in the future.
      </p>
      <p>
      Switchuk.com takes its responsibility of protecting your data very seriously and we do advise you get to know our practices – If there’s anything here you don’t understand or if you want to ask any questions, please feel free to contact us (detail are at the end).
      </p>
    </div>

    <div class="bg-light">
      <p class="font-weight-bold h4">Who is the Data Controller?</p>
      <div>
        <p>Switch Online Ltd (trading as switchuk.com), a UK limited company registered in England and Wales</p>
        <div class="row">
          <div class="col-2">
            Registered address:
          </div>
          <div class="col-10">
            Cedrus A Brookfield Lane West, Cheshunt, Waltham Cross, England, EN8 0QY.
          </div>
          <div class="col-2">
            Registered number:
          </div>
          <div class="col-10">
            13231861.
          </div>
        </div>
        <p>
          In this document Switch Online Ltd (trading as switchuk.com) may be referred to as “we”, “us”, or “our”.
        </p>
      </div>
      <div class="bg-light h4">
        <p class="font-weight-bold">What kind of personal data does switchuk.com process?</p>
      </div>
      <div>
        <p>Switchuk.com collects personal data for specific purposes, with that in mind we have created a list of the types of personal data that we may collect, either directly from yourself or from other sources, in order to achieve those purposes.</p>
        <p>
          The kind of personal data we may collect are:
        </p>
        <table class="table table-bordered border-dark table-sm">
          <thead class="thead-light">
            <tr>
              <th class="w-50">If you are a:</th>
              <th>We may process these types of personal data:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Lead
              </td>
              <td>
                Forename, surname, email, telephone number. Postcode IP address
              </td>
            </tr>
            <tr>
              <td>
                Partners
              </td>
              <td>
                Name, address, telephone, email
              </td>
            </tr>
            <tr>
              <td>
              Affiliates
              </td>
              <td>
              Name, address, telephone, email,
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          We also obtain personal data from third-party organizations to provide a product or service that you have requested.  We obtain personal data from Switch Online Ltd, trading as bill-switchers.com so that we can help you compare the best money-saving deals for broadband, energy, and mobile phone services.
        </p>
        <p>
          Our broadband, energy and mobile phone price comparison service is provided by Decision Technologies Ltd and we will share some of your personal data with them solely for the purposes of providing this service.  For example, some of your personal data will be used to automatically fill out parts of the price comparison service to save you time and ensure that the information is accurate and the results are tailored to your specific requirements.
        </p>
        <p>
          Decision Technologies Limited is a company based in England.  When you use the price comparison service they will act as a data controller with regards to your personal data and you should read their <a href="https://decision.tech/privacy-policy/">privacy policy</a> to understand how they use it and keep it secure.
        </p>
      </div>
      <div>
        <p>
          Switchuk.com is not responsible for the data protection compliance of any of the third parties it works with.
        </p>
      </div>
      <div class="bg-light">
        <p class="font-weight-bold h4">Your Rights, Our Responsibility</p>
      </div>
      <div>
        <p>There are several rights granted to you immediately upon providing us with your personal information; some of these are mentioned above. We’d like you to know that at switchuk.com we take your rights as a Natural Person seriously and will always conduct ourselves in a way that is considerate of our responsibility to serve your legal rights.
        </p>
        <h5><u>The Right of Access</u></h5>
        <p>
          This grants you the right to confirm whether or not your personal data is being processed, and to be provided with relevant details of what those processing operations are and what personal data of yours is being processed.
        </p>
        <p>
          If you would like access to the personal data we have about you, we ask that you contact us by using our details below.
        </p>
        <h5><u>The Right to Rectification</u></h5>
        <p>
          This one is fairly straight forward; if you notice that the data we have about you is inaccurate or incomplete, you may request we rectify the mistake. We will make every effort to respond to requests of this type immediately.
        </p>
        <h5><u>The Right to Objection</u></h5>
        <p>
          The right to object is a basic freedom all democracies enjoy. If you wish to object to the way we use, or have used, your personal data you may do so freely.
        </p>
        <h5><u>The Right to Portability</u></h5>
        <p>
          This is a legal right afforded to you that states we must pass on all of the details you have provided to us in a machine-readable format, either to your or to another provider of your choosing.
        </p>
        <h5><u>The Right to Complain</u></h5>
        <p>
          We will always try to maintain the highest standards and encourage the confidence our customers have in us as an organisation. In order that we can achieve this we do request that any complaints be first brought to our attention so we can properly investigate matters; if however you would like to complain about switchuk.com to a supervisory authority you may do so by contacting the Information Commissioners Office on 0303 123 1113, or by visiting their website - <a href="">https://ico.org.uk/concerns</a>
        </p>
      </div>
      <div class="bg-light">
        <p class="font-weight-bold h4">Switchuk.com contact details:</p>
      </div>
      <div>
        <p>
          Switch Online Ltd<br>
          Cedrus A Brookfield Lane West, Cheshunt, Waltham Cross, England, EN8 0QY<br>
          phil@switchuk.com
        </p>
      </div>
      <div class="bg-light">
        <p class="font-weight-bold h4">Who is the switchuk.com Ltd Data Protection Officer?</p>
      </div>
      <div>
        <p>
          If you wish to exercise any of the rights set out above or If you have concerns, <br> questions, complaints or need further information about our privacy statement or practices,
        </p>
        <p>you can contact our data protection office (DPO) at <a href="mailto:admin@switchuk.com">admin@switchuk.com</a></p>
      </div>
      <div>
        <p class="font-weight-bold h5">
          NB – this privacy notice (as at 05/08/21) is not compliant with the ICO recommendations or the UK GDPR and needs reviewing / updating.
        </p>
        <p class="font-weight-bold h5">
          It excludes all the necessary information as to how personal data is obtained; shared; stored; retention periods; etc.
        </p>
      </div>
      <div class="bg-light">
        <p class="font-weight-bold h4">For our purposes, as a bare minimum, they need to add:</p>
      </div>
      <div>
        <p>
          We also obtain personal data from third party organisations to provide a product or service that you have requested.  We obtain personal data from  Switch Online Ltd, trading as bill-switchers.com, so that we can help you compare the best money saving deals for broadband, energy and mobile phone services.
        </p>
        <p>
          Our broadband, energy and mobile phone price comparison service is provided by Decision Technologies Ltd and we will share some of your personal data with them solely for the purposes of providing this service.  For example, some of your personal data will be used to automatically fill out parts of the price comparison service to save you time and ensure that the information is accurate and the results are tailored to your specific requirements.
        </p>
        <p>
          Decision Technologies Limited is a company based in England.  When you use the price comparison service they will act as a data controller with regards to your personal data and you should read their <a href=""> privacy policy </a> to understand how they use it and keep it secure.
        </p>
      </div>
      <div>
        <p class="font-weight-bold h5">
          NB – adding the above information WILL NOT make their policy compliant but will to a minimal extent necessary for our purposes, cover the service that DT provides and how we will use the visitor personal data
        </p>
      </div>
      <div>
        <p>
          This privacy policy was last reviewed and updated on 26th March 2024
        </p>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
  export default{
    name: 'privacy_policy',
  }
</script>
