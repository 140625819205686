<template>
  <div class="row w-100 mt-3 mt-md-5 ml-0">
    <div class="col-lg-6 col-xl-6 col-6 mb-3 z-10" @click="redirect_to(`${deal.route}`)" v-for="deal in this.dealBtns">
      <div class="px-md-3 py-3 cursor-p bg-yellow d-flex flex-column align-items-center justify-content-center rounded-2xl shadow-box h-100 deal-btn">
        <img class="mb-3 text-yellow" v-if="deal.title == 'Energy'" :src="deal.img" width="55">
        <img class="mb-3 text-yellow" v-if="deal.title == 'Sim'" :src="deal.img" width="33">
         <img class="mb-3 text-yellow" v-if="deal.title == 'Mobile phones'" :src="deal.img" width="40">
        <img class="mb-3 text-yellow" v-if="deal.title == 'Broadband'" :src="deal.img" width="40">
        <span class="h5 font-weight-normal align-middle mb-0 text-dark">
          {{ deal.title }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'DealsButton',
    data: function() {
      return {
        dealBtns: [
          { route: '/mobiles', title: 'Mobile phones', img: require('images/phone2.png') },
          { route: '/sim_only_deals', title: 'Sim', img: require('images/sim2.png') },
          { route: '/energy', title: 'Energy', img: require('images/energy2.png') },
          { route: '/broadband', title: 'Broadband', img: require('images/broadband2.png') },
           //{ route: '/loans', title: 'Loan deals', img: require('images/svg/loan.svg') }, 

        ]
      }
    },
    methods: {
      redirect_to(route) {
        var params = window.location.search
        switch(route) {
          //  case '/loans':
          //   window.location = `https://switchuk.monevo.co.uk/`
            // break;
          case '/sim_only_deals':
            window.location = `https://mobile-phones.switchuk.com/sim-deals${params}`
            break;
          case '/mobiles':
            window.location = `https://mobile-phones.switchuk.com/${params}`
            break;
          case '/broadband':
            window.location = `http://broadband.switchuk.com/${params}`
            break;
          case '/energy':
            window.location = `https://energy.switchuk.com/${params}`
            break;
          default:
            window.location = 'https://switchuk.com/'
        }
      }
    },
  }
</script>
<style type="text/css">
  .deal-btn {
    border: 2px solid transparent;
  }
  .deal-btn:hover{
    border: 2px solid #fec022;
  }
</style>
