<template>
  <div class="chart-data">
      <span class="detail-value">{{this.stringValue}}</span>
      <doughnut-chart :chart-data="this.chart" :doughnut-height="'106px'" :doughnut-width="'106px'"></doughnut-chart>
      <span  class="font-weight-bold">{{this.name}}</span>
    </div>
  </div>
</template>

<script>
  import DoughnutChart from '../../shared/doughnut_chart'

  export default {
    props: ['color', 'secondColor', 'name', 'value', 'stringValue', 'maxValue'],
    components: {DoughnutChart},
    data() {
      var backgroundColor= [this.color, this.secondColor]
      var labels =[this.name, '']
      return {
        chart: {
          labels: labels,
          datasets: [{
            backgroundColor: backgroundColor,
            data: [this.value, this.maxValue - this.value],
          }],
          id: this.id
        }
      }
    },
  }
</script>
<style type="text/css" lang="scss">
  @media only screen and (max-width: 425px){
    .detail-value{
      font-size: 13px;
      top: 46% !important;
    }
  }
  .chart-data{
    width: 106px;
  }
  .detail-value{
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-90%);
  }
</style>
