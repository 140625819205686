<template>
  <div v-if="loading">
    <div class="container">
      <div class="row pt-md-5 pt-3">
        <div class="mob-image animated-background col-lg-3 text-center mb-2 mx-auto"></div>
        <div class="col-lg-9">
          <div class="row mx-0 border">
            <div class="col logo-image animated-background"></div>
            <div class="col header animated-background"></div>
            <div class="col redirect-btn animated-background"></div>
          </div>
          <div class="border pt-0 p-md-3 mt-2">
            <div class="row no-gutters">
              <div class="col-md-3 d-flex d-md-block justify-content-between align-items-center p-x-0 px-2 mb-2 mb-md-0">
                <div class="col text-line animated-background mt-4"></div>
              </div>
              <div class="col-md-9">
                <div class="row w-100 m-0">
                  <div class="col-4 border-right d-flex justify-content-center text-center bonus">
                    <div class="mx-auto animated-background"></div>
                  </div>
                  <div class="col-4 border-right d-flex justify-content-center text-center bonus">
                    <div class="mx-auto animated-background"></div>
                  </div>
                  <div class="col-4 d-flex justify-content-center text-center bonus">
                    <div class="mx-auto animated-background"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row my-3 offset-md-3 px-2 px-md-0 no-gutters">
              <div class="col-4 text-center px-1">
                <div class="border rounded">
                  <div class="m-1 animated-background"></div>
                </div>
              </div>
              <div class="col-4 text-center px-1">
                <div class="border rounded">
                  <div class="m-1 animated-background"></div>
                </div>
              </div>
              <div class="col-4 text-center px-1">
                <div class="border rounded">
                  <div class="m-1 animated-background"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'show_page_loader',
    props: ['loading']
  }

</script>

<style lang="scss" scoped >
  .text-line {
    height: 20px;
  }

  // Page Elements
  .mob-image {
    height: 200px;
    width: 200px;
  }

  .logo-image{
    height: 70px;
    margin: 4px;
  }

  .bonus{
    height: 106px;
    div {
      height: 90px;
      width: 106px;
      border-radius: 50%;
    }
  }

  .header{
    margin: 4px;
    height: 70px;
  }

  .redirect-btn{
    margin: 4px;
    height: 70px;
  }
  @media only screen and (max-width: 320px) {
    .bonus{
      div {
        height: 66px;
        width: 106px;
        border-radius: 50%;
      }
    }
  }
  @media only screen and (min-width: 321px) and (max-width: 375px) {
    .bonus{
      div {
        height: 75px;
        width: 106px;
        border-radius: 50%;
      }
    }
  }
</style>
