<script>
import { Doughnut, mixins } from 'vue-chartjs';

export default {
  extends: Doughnut,
  props: {
    doughnutHeight: String,
    doughnutWidth: String,
    customOpts: Object
  },
  mixins: [mixins.reactiveProp],
  mounted() {
    let defaultOpts = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
        position: 'right',
        fullWidth: false,
        labels: { usePointStyle: true },

        // remove/comment this line to enable onclick event of legends.
        onClick: (e) => e.stopPropagation()
      },
      cutoutPercentage: 70,
      tooltips: { enabled: false },
    };

    let options = Object.assign(defaultOpts, this.customOpts);

    this.addPlugin({
      id: 'donutChartHeight',
      beforeInit: chart => {
        const parent = chart.canvas.parentNode;
        Object.assign(parent.style, { position: 'relative', height: this.doughnutHeight, width: this.doughnutWidth });
      },
    });

    return this.renderChart(this.chartData, options);
  },
};
</script>
