<template>
  <div class="col-xl-3 px-2 d-none d-lg-block" id="filersList">
    <div class="row d-lg-none bg-white justify-content-between px-5 py-3 filter-close">
      <span class="font-weight-bolder">Filters</span>
      <a href="javascript:;" class="text-secondary" @click="hideFilter"> Close
      </a>
      <button v-if="!model" type="button" class="btn btn-link m-0 text-right p-0"
        @click="clearFilters()">
          Clear filters
      </button>
    </div>
    <div class="bg-light-blue rounded-lg-bottom filter-content sticky-top">
      <div class="justify-content-between align-items-center rounded-lg-top py-2 d-lg-flex d-none bg-light-blue sticky-filter-header">
        <h3 class="ml-1 m-0 font-weight-bold">Filters</h3>
        <h4 v-if="!model" type="button" class="btn btn-link m-0 text-right"
        @click="clearFilters()">
          Clear filters
        </h4>
      </div>
      <div class="p-3 filter-components" :key="componentKey">
        <deal-type @update-filter="updateFilter"></deal-type>
        <cost-type @update-filter="updateFilter"></cost-type>
        <brand-type @update-filter="updateFilter" v-if="!model"></brand-type>
        <network-type productType="other" @update-filter="updateFilter"></network-type>
        <merchant-type productType="other"@update-filter="updateFilter"></merchant-type>
        <minute-type @update-filter="updateFilter"></minute-type>
        <data-type @update-filter="updateFilter" ></data-type>
        <contract-type @update-filter="updateFilter"></contract-type>
      </div>
    </div>
    <div class="sticky-button d-lg-none bg-white justify-content-between py-2 px-3 filter-close">
       <button class="btn btn-blue w-100 text-white" @click="hideFilter">
         Show Results
       </button>
    </div>
  </div>
</template>
<script>
  import BrandType from './brands'
  import DealType from './deal_type'
  import ModelType from './model'
  import CostType from './cost_type'
  import NetworkType from './network'
  import MerchantType from './merchant'
  import MinuteType from './minute'
  import DataType from './data'
  import ContractType from './contract'
  import {mapMutations} from 'vuex'

  export default{
    name: "Filters",
    data(){
      return{
        componentKey: 0,
      }
    },
    props: ['model'],
    components: {
      BrandType,
      DealType,
      ModelType,
      CostType,
      NetworkType,
      MerchantType,
      MinuteType,
      DataType,
      ContractType,
    },
    methods:{
      ...mapMutations([
          'setProductsList',
          'setPageIndex'
      ]),
      updateFilter(filter, value){
        this.setProductsList()
        this.setPageIndex(0)
        this.addRoute(filter, value)
        this.$emit('update-products')
      },
      clearFilters(){
        var currentPage = this.$router.currentRoute["fullPath"]
        currentPage = currentPage.split('?')[0]
        this.$router.replace(currentPage)
        this.componentKey += 1
      },
      addRoute: function(filter, value){
        eval(`this.$router.push({ query: { ...this.$route.query, ${filter} : "${value}" }})`)
      },
      hideFilter() {
        document.getElementById('filersList').classList.remove("filter-on-mobile", "d-block")
        document.getElementsByTagName('body')[0].classList.remove("overflow-hidden")
      }
    }
  }
</script>
<style type="text/css" lang="scss" scoped>
  @media only screen and (min-width: 1024px) {
    .filter-content{
      top: 20px;
      box-shadow: 0 1px 2px rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
      .filter-components{
        overflow-y: scroll;
        height: calc( 100vh - 100px );
        overflow-x: hidden;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dedcdc;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #0569b2;
  }
  @media only screen and (min-width: 1024px) {
    .sticky-filter-header{
      position: relative;
      z-index: 1;
      box-shadow: 0 1px 3px rgba(60,64,67,.3), 0 0 0 0 rgba(60,64,67,.15);
      background-color: #cfe7fc !important;
    }
  }

  #filersList{
    z-index: 0;
  }
</style>

