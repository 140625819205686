<template>
  <div class="div-minutes pt-4">
    <h5 class="pb-2 m-0">Data</h5>
    <div class="w-100 row m-0 align-self-center justify-content-center">
      <div class="position-relative mt-2 mt-md-0 input-div3"  v-for="(value, name) in this.data" :key="name">
        <input
        name="data"
        class="opacity-0 data cursor-p form-control position-absolute input-tag-radio3"
        :id="`data-${name}`"
        type="checkbox"
        :value="name"
        v-model="checkedData"
        v-on:change="setFilter"
        >
        <label :for="`data-${name}`" class="input-radio3 text-center rounded py-2 mx-auto cursor-p">
          <div class="w-100">
            <small v-if="name != 'unlimited'" class="text-muted d-block">up to</small>
            <span class="font-weight-bold">{{value}}</span>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
  export default{
    name: "DataFilter",
    data: function () {
      return {
        checkedData: this.$route.query.data ? this.$route.query.data.split(",") : [],
        data:{
          1000: "1GB",
          2000: "2GB",
          5000: "5GB",
          10000: "10GB",
          50000: "50GB",
          'unlimited': 'Unlimited'
        }
      }
    },
    methods: {
      setFilter(event) {
        var checkboxes = document.getElementsByName('data')
        checkboxes.forEach((item) => {
            if (item !== event.target) {
              item.checked = false
            }else{
               this.checkedData = event.target.checked ? [event.target.value] : []
            }
        })
        this.$emit('update-filter', 'data', this.checkedData);
      }
    }
  }
</script>
