<template >
  <div class="container product-show">
    <div class="row pt-md-5 pt-3">
      <div class="col-lg-3 text-center mb-2">
        <img :src="product.package_variation.image1" class="product-image float-lg-right">
      </div>
      <div class="col-lg-9">
        <div class="card p-3 shadow-box product-box">
          <div class="row align-items-center">
            <img v-bind:src="require(`images/logos/${product.network.name.split(' ').join('')}.png`)" height="60px">
            <div class="col border-left">
              <h5 class="text-capitalize text-secondary font-weight-bold mb-0">{{product.brand}}</h5>
              <h3 class="text-capitalize font-weight-bold mb-0">{{product.name}} {{product.package_variation.color}}</h3>
              <p v-if="product.package.contract_length>0" class="m-0 "><strong  class="text-blue">{{product.package.contract_length}}</strong>  Months contract
              </p>
              <p v-else class="m-0 ">
                <strong class="text-blue">No</strong> contract
              </p>
            </div>
            <div class="col-md text-md-right">
              <a @click="setSpin" class="btn d-flex justify-content-center align-items-center px-5 w-100 text-white font-weight-bold py-3 btn-blue" :href="product.package_variation.url">
                <span class="spinner mr-2 spinner-border spinner-border-sm invisible mt-1" role="status" aria-hidden="true"></span>
                <span>
                  Continue
                </span>
              </a>
            </div>
          </div>
        </div>
        <div class="card pt-0 p-md-3 mt-2">
          <div class="row no-gutters">
            <div class="col-md-3 d-flex d-md-block justify-content-between align-items-center color-selector-box p-x-0 px-2 mb-2 mb-md-0">
                <h6 class="text-capitalize d-none d-md-block font-weight-bold mb-0 text-secondary">Select Your Color</h6>
                <dropdown :options="product.colors"
                          :selected="{name: product.package_variation.color }"
                          v-on:updateOption="changeColor"
                          :placeholder="'Select a Color'"
                          :closeOnOutsideClick="true">
                </dropdown>

            </div>
            <div class="col-md-9">
              <div class="row w-100 m-0">
                <div class="col-4 border-right d-flex justify-content-center text-center">
                   <chart-data
                      color="#28a745"
                      secondColor="#28a7466b"
                      name="Call"
                      :value="product.package.minutes == null ? product.package.max_minutes : product.package.minutes"
                      :stringValue="product.package.formatted_minutes"
                      :maxValue="product.package.max_minutes"
                  ></chart-data>
                </div>
                <div class="col-4 border-right d-flex justify-content-center text-center">
                  <chart-data
                      color="#ffbe07"
                      secondColor="#ffbe0752"
                      name="Data"
                      :value="product.package.data == null ? product.package.max_data : product.package.data"
                      :stringValue="product.package.formatted_data"
                      :maxValue="product.package.max_data"
                  ></chart-data>
                </div>
                <div class="col-4 d-flex justify-content-center text-center br-md-none">
                  <div class="d-flex align-items-center justify-content-center">
                    <chart-data
                      color="#0469b2"
                      secondColor="#0469b266"
                      name="Texts"
                      :value="product.package.texts == null ? product.package.max_texts : product.package.texts"
                      :stringValue="product.package.formatted_texts"
                      :maxValue="product.package.max_texts"
                    ></chart-data>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3 offset-md-3 px-2 px-md-0 no-gutters">
            <div class="col-4 text-center px-1">
              <div class="border p-md-4 p-2 rounded">
                <h2 class="font-weight-bold mb-0">
                  £{{product.package.upfront_cost}}
                </h2>
                <small>Upfront Cost</small>
              </div>
            </div>
            <div class="col-4 text-center px-1">
              <div class="border p-md-4 p-2 rounded">
                <h2 class="font-weight-bold mb-0">
                  £{{product.package.monthly_cost}}
                </h2>
                <small>Monthly Cost</small>
              </div>
            </div>
            <div class="col-4 text-center px-1">
              <div class="border p-md-4 p-2 rounded">
                <h2 class="font-weight-bold mb-0">
                  £{{product.package.total_cost}}
                </h2>
                <small>Total Cost</small>
              </div>
            </div>
          </div>
          <div class="offset-md-3 d-flex justify-content-between align-items-center px-3 py-2 border-bottom specification" v-for="(value, specification) in product.package.device_specifications">
                <h6 class="text-capitalize mb-0 mr-2">{{cleanSpecification(specification)}}</h6>
                <p class="text-capitalize mb-0 text-secondary text-truncate">{{value}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import http from '../../../common/http.js';
  import _ from "underscore"
  import {mapMutations, mapGetters} from 'vuex';
  import ChartData from '../../deal_details/chart_data';
  import dropdown from 'vue-dropdowns';

export default {
  name: 'ShowProduct',
  components: {
    ChartData,
    dropdown
  },
  data() {
    return{
      arrayOfObjects: [{name: "sadas"},{name: "assa"}],
    }
  },
  props: [
    'product'
  ],
  computed: {
    ...mapGetters([
      'spinner',
      'loader'
    ]),
  },
  methods:{
    changeColor: function(variation){
      this.$router.push({ query: { ...this.$route.query, variation_id: variation.id }})
      this.$emit('get-package')
    },
    cleanSpecification: function(specification){
      return specification.split('_').join(' ')
    },
    setSpin(event){
      event.currentTarget.querySelector('.spinner').classList.remove('invisible');
    }
  }
}
</script>
<style lang="scss" scoped>
  .product-image{
    max-height: 300px;
    max-width: 100%;
    max-height: 100%;
  }
  .spinner {
    margin-bottom: 0.125rem;
    margin-left: -1.5rem !important;
  }
</style>

