<template>
  <div class="div-brands pt-4">
    <h5 class="pb-2 m-0">Brand</h5>
    <div class="w-100 row m-0 align-self-center justify-content-center">
      <div class="position-relative mt-2 mt-md-0 input-div2"  v-for="brand in this.brands" :key="brand.id">
        <input
        name="brand"
        class="opacity-0 brand cursor-p form-control position-absolute input-tag-radio2"
        type="checkbox"
        :id="`brand-${brand.name.split(' ').join('')}`"
        :value="brand.id"
        v-model="checkedBrands"
        v-on:change="setFilter"
        >
        <label :for="`brand-${brand.name.split(' ').join('')}`" :class="`${brand.name.split(' ').join('')}-img bg-img-position input-radio2 rounded py-2 mx-auto cursor-p`">
        </label>
      </div>
    </div>
  </div>
</template>

<script>
  import http from '../../common/http'
  export default{
    name: "Brands",
    data: function () {
      return {
        brands: [],
        checkedBrands: this.$route.query.brands ? this.$route.query.brands.split(",") : []
      }
    },
    mounted(){
      this.getBrands()
    },
    methods: {
      setFilter() {
        this.$emit('update-filter','brands', this.checkedBrands);
      },
      getBrands: function() {
        http.get('/brands.json')
          .then( res => {
            this.brands = res.data
          }).catch(err => {
            console.log(err)
          })
      }
    }
  }
</script>
