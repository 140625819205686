<template>
  <div class="d-flex mb-4 why-us">
    <span class="icon-wrapper border-bottom border-warning">
      <span class="icon p-3 border border-warning rounded-circle text-center">
        <font-awesome-icon :icon="this.option.icon" class="fs-23 text-warning align-bottom" />
      </span>
    </span>
    <div class="ml-2 mt-2">
      <h6 class="text-warning font-weight-bold mb-0">{{ this.option.heading }}</h6>
      <p>
        <i>{{ this.option.data }}</i>
      </p>
    </div>
  </div>
</template>
<script type="text/javascript">
  export default {
    props: [
      'option',
    ]
  }
</script>
<style scoped>
  .icon-wrapper {
    width: 80px;
    height: 45px;
    transform: rotate(-65deg);
    overflow: hidden;
    position: relative;
    box-shadow: 0 6px 6px -6px #00223a;
  }

  .icon {
    width: 60px;
    height: 60px;
    position: absolute;
    right: 9px;
    transform: rotate(65deg)
  }
</style>
