<template>
  <div class="select-phone py-2">
    <div class="d-lg-flex d-block align-items-center">
      <div class="selected-phone">
        <div v-if="selectedPhoneImage" :model="model">
          <div class="text-center selected-phone-img mt-2 mb-md-2 mb-4 mx-auto">
            <img class="thumb" :src="selectedPhoneImage" style="object-fit: contain;" />
          </div>
        </div>
        <div v-else class="text-center mx-auto selected-phone-img py-2">
          <img :src="require('images/empty-phone.png')" class="h-100 py-2">
        </div>
      </div>
      <div class="search-bar d-flex align-items-center w-100">
        <div
          class="bg-white position-relative compare-handset col-12 col-lg-11 p-0"
          id="compare_handset" >
          <div class="autosuggest-container">
            <v-select
              placeholder="iphone 12" class="w-100" :class="`goto-${model}`"
              v-model="selectedPhone" :options='options'
            />
          </div>
          <div class="d-none d-lg-flex row px-0 py-2 mt-3 mx-0 w-100 border bg-light
            search-bar-icons align-items-center">
            <div class="col-4 border-right px-0 py-1 text-center">
              <img :src="require('images/5g.png')" class="mb-1" height="20px"/>
              <p class="mb-0">5G Handsets</p>
            </div>
            <div class="col-4 px-0 py-1 border-right text-center">
              <img :src="require('images/wifi.png')" class="mb-1" height="20px"/>
              <p class="mb-0">Unlimited Data</p>
            </div>
            <div class="col-4 px-0 py-1 text-center">
              <img :src="require('images/phone.png')" class="mb-1" height="20px"/>
              <p class="mb-0">Unlimited Calls</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faMobile } from '@fortawesome/free-solid-svg-icons'
  import {mapGetters} from 'vuex'
  library.add(faMobile)
  export default{
    name:"compare-deals",
    props: [
      'productName',
      'model',
      'count',
      'allProducts',
      'options',
      'defaultValue'
    ],
    components:{
      vSelect
    },
    data(){
      return{
        query: "",
        selected: "",
        prevModel: "",
        packages:[],
        selectedPhoneImage: "",
        product: {model_one:"",model_two:""},
      }
    },
    computed: {
      selectedPhone: {
        /* Method for getting the value of selected phone in select box - start */
        get(){
          if(this.$route.query["model_one"] || this.$route.query["model_two"]){
            if(this.defaultValue){
              this.product[this.model] = this.allProducts[this.defaultValue]
              this.selectedPhoneImage = this.product[this.model][0].avatar
              return this.defaultValue
            }
          }
        },
        /* Method for getting the value of selected phone in select box - end */

        /* Method for setting the selected phone in select box and adding it in the route - start */
        set(value){
          this.product[this.model] = this.allProducts[value]
          this.selectedPhoneImage = this.product[this.model][0].avatar
          eval(`this.$router.push({ query: { ...this.$route.query, ${this.model} : "${this.product[this.model][0].device_name}" }})`)
        }
        /* Method for setting the selected phone in select box and adding it in the route - end */
      },
      ...mapGetters([
        'packageList',
      ])
    },
  }
</script>

<style type="text/css" lang="scss" scoped>
  .icons{
    font-size: 190px;
  }
  .fa-cog {
    color: #8080809e;
  }
  .search-bar{
    height: 42px;
    .search-bar-icons{
      font-size : 12px;
      img{
        object-fit: contain;
      }
    }
  }
  .selected-phone{
    .selected-phone-img{
      height:180px;
      width:180px;
      img{
        object-fit:contain;
      }
      @media screen and (max-width:575px){
        height:150px;
        width:150px;
      }
    }
  }
</style>
