<template>
  <div class="table-scroll" @scroll="networkScroll">
    <div class="d-flex">
      <div class="col-md-3 px-lg-3 px-2 col-6 border-right pt-3" v-for="pkg in packages" :key="pkg.id">
        <div
          class="rounded-lg p-lg-3 p-2 mb-3 shadow deal-card cursor-p bg-white"
          v-for="pack in pkg"
          :key="pack.id"
          >
          <div class="border-bottom d-flex w-100 justify-content-between py-2">
            <p class="text-center m-0 title">Upfront cost</p>
            <p class="text-center m-0 font-weight-bold">
              £{{pack["package"]["upfront_cost"]}}
            </p>
          </div>
          <div class="border-bottom d-flex w-100 justify-content-between py-2">
            <p class="text-center m-0 title">Monthly Cost</p>
            <p class="text-center m-0 font-weight-bold">
              £{{pack["package"]["monthly_cost"]}}
            </p>
          </div>
          <div class="text-center w-100 pt-2">
            <button
              @click.stop="$router.push({path:`/packages/${pack['id']}`, query: {variation_id: pack['package']['id']}})"
              class="btn text-white btn-link px-3 w-100"
              >
                View
              <small class="ml-2">
                <font-awesome-icon icon="arrow-right"/>
              </small>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
  import _ from 'lodash';

  library.add(faArrowRight)
  export default{
    name: 'data-table',
    created(){
      this.showtab()
    },
    props:['packages'],
    methods: {
      showtab(){
        var dataTables = document.getElementsByClassName("data-table")
        if (dataTables.length > 0) {
          dataTables[0].classList.remove("d-none");
        }
      },

      networkScroll(){
        $(".table-scroll").on("scroll", function () {
          $(".network-icons").scrollLeft($(this).scrollLeft());
        });
      },
    },
  }
</script>

<style type="text/css" lang="scss" scoped>
  .table-scroll{
   animation: fadeinout 0.4s linear forwards;
   opacity:0;
    overflow-x: scroll;
    .deal-card{
      border: 1px solid transparent;
      transition: all 0.3s ease-in-out;
      .title,.btn-link{
        font-size:14px;
      }
      .btn-link{
        background : #0469b2;
        text-decoration : none;
        transition: all 0.3s ease-in-out;
      }
      &:hover{
        border : 1px solid #0469b2 !important;
        .btn-link{
          transform : scale(1.05);
        }
      }
    }
    @keyframes fadeinout {
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    &::-webkit-scrollbar
    {
      height: 5px;
      background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #eff7ff;
    }
  }
</style>
