 <template>
  <!-- deals section -->
  <div class="col-xl-9 px-2">
    <deals-loader v-if="this.productsList.length <= 0 && this.loader" />
    <div v-else>
      <div v-if="this.productsList.length > 0">
        <div v-for="product in this.productsList">
          <div class="row w-100 m-0">
            <div class="col-md-3 pl-md-0 px-0" v-if="product.package.promo">
              <p id="best-deal" class="m-0 special-deal-text">Special Deal</p>
            </div>
          </div>
          <div class="deal-div bg-white position-relative p-1 mb-4 py-md-1 py-3" :class="{'special-deal' : product.package.promo }">
            <div class="row w-100 m-0 align-items-center">
              <div class="col-md-2 col-4 border-right text-center br-md-none">
                <img class="thumb" :src="product.package.thumb" >
              </div>
               <!-- mobile view-->
              <div class="col-8 d-md-none d-block">
                <h4 class="m-0 text-blue"> {{getname(product)}}</h4>
                <p v-if="product.package.contract_length > 0" class="m-0 "><span class="text-blue">{{product.package.contract_length}}</span> Months contract</p>
                <p v-else class="m-0 "><span> No</span> contract</p>
                <div class="d-flex text-center d-md-none d-block w-100 py-3 border">
                  <div class="col-6 border-right text-center align-self-center">
                    <p class="m-0"><strong class="h5 font-weight-bold">£{{product.package.upfront_cost}}</strong></p>
                    <p class="m-0 font-weight-bold">Upfront</p>
                  </div>
                  <div class="col-6 text-center align-self-center">
                    <p class="m-0"><strong class="h5 font-weight-bold">£{{product.package.monthly_cost}}</strong></p>
                    <p class="m-0 font-weight-bold">Per month</p>
                  </div>
                </div>
              </div>
              <!-- End mobile view-->

              <div class="col-md-6 px-md-2 px-0 align-self-end">
                 <!-- Desktop view-->
                <div class="text-md-left text-center py-3 d-none d-md-block">
                  <h4 class="m-0 text-blue"> {{getname(product)}} </h4>
                  <p v-if="product.package.contract_length > 0" class="m-0 "><span class="text-blue">{{product.package.contract_length}}</span> Months contract</p>
                  <p v-else class="m-0 "><span> No</span> contract</p>
                </div>
                 <!-- End Desktop view-->
                <div class="row w-100 m-0 py-3 py-md-0">
                  <div class="col-4 border-right text-center d-flex justify-content-center">
                     <chart-data
                        color="#28a745"
                        secondColor="#28a7466b"
                        name="Call"
                        :value="product.package.minutes == null ? product.package.max_minutes : product.package.minutes"
                        :stringValue="product.package.formatted_minutes"
                        :maxValue="product.package.max_minutes"
                    ></chart-data>
                  </div>
                  <div class="col-4 border-right text-center d-flex justify-content-center">
                    <chart-data
                        color="#ffbe07"
                        secondColor="#ffbe0752"
                        name="Data"
                         :value="product.package.data == null ? product.package.max_data : product.package.data"
                        :stringValue="product.package.formatted_data"
                        :maxValue="product.package.max_data"
                    ></chart-data>
                  </div>
                  <div class="col-4 border-right text-center br-md-none d-flex justify-content-center">
                    <div class="d-flex align-items-center justify-content-center">
                      <chart-data
                        color="#0469b2"
                        secondColor="#0469b266"
                        name="Texts"
                        :value="product.package.texts == null ? product.package.max_texts : product.package.texts"
                        :stringValue="product.package.formatted_texts"
                        :maxValue="product.package.max_texts"
                      ></chart-data>
                    </div>
                  </div>
                </div>

              </div>
              <!-- desktop view -->
              <div class="col-md-2 text-center align-self-end border-right d-md-block d-none pb-5">
                <p class="m-0"><strong class="h5 font-weight-bold">£{{product.package.upfront_cost}}</strong></p>
                <p class="m-0">Upfront</p>
              </div>
              <div class="col-md-2 text-center align-self-end py-md-2 px-md-2">
                <!-- desktop view-->
                <p class="m-0 d-none d-md-block lh-1"><strong class="h5 font-weight-bold">£{{product.package.monthly_cost}}</strong>
                </p>
                <p class="m-0 d-none d-md-block lh-1">Per month</p>
                <!--  -->
                <button class="btn btn-blue px-lg-4 px-md-2 px-5 my-md-2 mt-2 text-white w-100" @click.stop="$router.push({path: `/packages/${product.id}`, query: {variation_id: product.package.id}})" v-on:click="increaseViewpoints">See Deal</button>
                <div class="mx-auto d-none d-md-block network-logo" :class="`${product.package.name.split(' ').join('')}-img`" ></div>
              </div>
            </div>
          </div>
        </div>
        <infinite-loading @infinite="infiniteHandler"></infinite-loading>
      </div>
      <div v-else-if="!this.loader">
        <div class="border text-center rounded p-5">
          <img id="no-prod-img" :src="require('images/no-data.png')" class="no-prod-img">
          <p>No Products found</p>
          <button
            type="button"
            class="bg-blue other-deals btn rounded-pill text-white"
            @click="removeFilters"
          >
            Explore Other Deals
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
 import http from '../../common/http';
 import {mapMutations, mapGetters} from 'vuex';
 import InfiniteLoading from 'vue-infinite-loading';
 import ChartData from '../deal_details/chart_data';
 import DealsLoader from '../../common/deals_loader'
  export default{
    name: "Deals",
    components: {
      InfiniteLoading,
      ChartData,
      DealsLoader
    },
    data: function(){
      return{
        products: []
      }
    },
    mounted(){
      this.updateProducts()
    },
    computed: {
      ...mapGetters([
        'pageIndex',
        'productsList',
        'loader'
      ]),
    },
    methods:{
      ...mapMutations([
          'setPageIndex'
      ]),
      infiniteHandler($state){
        this.setPageIndex(this.pageIndex + 1)
        this.$emit('update-products',null,$state)
      },
      increaseViewpoints: function(){
        http.patch(`/package_variations/${this.$route.params.id}.json`, this.$route.query)
      },
      getname(product){
        let brand = product.brand.charAt(0).toUpperCase() + product.brand.slice(1);
        let model = product.name
        let color = null
        let contains = null
        let fullname = brand + " " + model
        if (product.package.color != null) {
          color = product.package.color.split(' ')
          contains = color.length > 1 ? model.includes(color[1]) : model.includes(color[0])
          return contains ? fullname : `${fullname} ${color.join(' ')}`
        }
        return fullname
      },
      removeFilters() {
        let filters_load = true
        this.$router.push({path: '/mobiles/contract_phone_deals'})
        this.updateProducts(filters_load)
      },
      updateProducts(filters_load){
        this.$emit('update-products',filters_load)
      },
    },
  }
</script>

<style type="text/css" lang="scss" scoped>
  @media only screen and (max-width: 280px) {
    #no-prod-img{
      width: 200px;
    }
  }
</style>
