<template>
  <div class="pb-5">
    <div class="container mt-2 mt-md-5 px-0">
      <sort-filter :product_type='"Phone"' @update-products="updateProducts"></sort-filter>
      <search-handset v-if="this.$route.query.deal_type == 'phone' ||
        this.$route.query.deal_type == undefined "
        @update-products="updateProducts" :key="componentKey"/>
      <div class="row w-100 m-0">
        <filters @update-products="updateProducts" :key="componentKey"></filters>
        <sim-deals
          v-if="this.$route.query.deal_type == 'sim'"
          @update-products="updateProducts"
        />
        <deals v-else @update-products="updateProducts"></deals>
      </div>
    </div>
  </div>
</template>
<script>
  import HeaderDeals from './mobile_packages/header_deals'
  import SortFilter from './filters/sort_filter'
  import Filters from './filters/filters'
  import Deals from './mobile_packages/deals'
  import SimDeals from './sim/sim_deals'
  import {mapMutations} from 'vuex';
  import SearchHandset from './filters/search_handset'

  export default {
    name: 'Packages',
    components: {
      HeaderDeals,
      SortFilter,
      Filters,
      Deals,
      SimDeals,
      SearchHandset
    },
    data() {
      return {
        componentKey: 0,
      }
    },
    created(){
      this.setProductsList([])
      this.setPageIndex(0)
    },
    methods:{
      ...mapMutations([
        'setProductsList',
        'setPageIndex'
      ]),
      updateProducts(filters_load = null, loaderState = null){
        if (filters_load) {
          this.componentKey += 1
        }
        this.$store.dispatch('fetchProducts', loaderState)
      },
    }
  }
</script>
