import VueRouter from "vue-router";
import Home from "../components/home/home";
import Packages from "../components/packages/packages";
import Mobiles from "../components/packages/mobiles";
import ShowProduct from "../components/packages/show_packages/show_package";
import Sims from "../components/packages/sim/sims";
import BadCredits from "../components/packages/sim/badcredits";
import Model from "../components/packages/model_page/model";
import CompareDeals from "../components/packages/compare_deals/comparing";
import PrivacyPolicy from "../components/policies/privacy_policy";
import CookiePolicy from "../components/policies/cookie_policy";
import TermsAndConditions from "../components/policies/terms_and_conditions";
const routes = [
  {
    path: "/compare_deals",
    name: "Compare Deals",
    component: CompareDeals,
  },
  {
    path: "/mobiles/contract_phone_deals",
    name: "Mobile Deals",
    component: Packages,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/mobiles",
    name: "Mobiles",
    component: Mobiles,
  },
  {
    path: "/packages/:id",
    name: "Show Product",
    component: ShowProduct,
  },
  {
    path: "/sim_only_deals",
    name: "Sims",
    component: Sims,
  },
  {
    path: "/no_credit_check_sim_only_deals",
    name: "Sims BadCredit",
    component: BadCredits,
  },
  {
    path: "/model_deals",
    name: "Model",
    component: Model,
  },
  {
    path: "/privacy_policy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms_and_conditions",
    name: "Terms and Conditions",
    component: TermsAndConditions,
  },
  {
    path: "/cookie_policy",
    name: "Cookie Policy",
    component: CookiePolicy,
  },
];

const router = new VueRouter({ routes, mode: "history" });

router.beforeEach((to, from, next) => {
  let badCreditPath = "/no_credit_check_sim_only_deals";
  let badCredit = document.cookie.includes("bc_cookie=true");
  //1
  if (to.path === badCreditPath && !from.name) {
    next();
  }
  //2
  else if (
    from.path === badCreditPath &&
    badCredit &&
    to.name == "Show Product"
  ) {
    next();
  }
  //3
  else if (to.path === badCreditPath && from.path === badCreditPath) {
    next({
      query: to.query,
    });
  }
  //4
  else if (to.name === "Sims BadCredit" && from.name === "Show Product") {
    next();
  }
  //5
  else if (badCredit) {
    next(badCreditPath);
  }
  //6
  else if (!badCredit) {
    next();
  }
});

export default router;
