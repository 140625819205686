<template>
  <header id="masthead" class="z-10 position-relative">
    <nav>
      <div class="header-bg" v-if="$route.path != '/'">
        <div class="container">
          <div class="row w-100 m-0 py-3 align-items-center justify-content-between">
            <a href="javascript:;"  @click.stop="$router.push({path: '/'})">
              <img :src="require('images/logo.png')" class="logo-img py-3">
            </a>
            <!-- <div class="head-button">
              <button class="btn btn-warning px-4 py-2 d-none d-md-block mx-auto font-weight-bold" @click.stop="$router.push({path: '/compare_deals'})"
               @click="$parent.updateViewKey()">Start Comparing
              </button>
              <button class="btn btn-warning px-2 py-2 d-md-none d-block mx-auto font-weight-bold" @click.stop="$router.push({path: '/compare_deals'});"
                @click="$parent.updateViewKey()">
                compare
                <img class="ml-2" :src="require('images/svg/compare-ph.svg')" width="25">
              </button>
            </div> -->
          </div>
        </div>
      </div>
      <div class="bg-imgheader" v-if="$route.path === '/'">
        <div class="container">
          <div class="row w-100 m-0 py-3 align-items-center justify-content-center justify-content-lg-between">
            <a href="javascript:;"  @click.stop="$router.push({path: '/'})">
              <img :src="require('images/logo.png')" class="logo-img py-3">
            </a>
            <!-- <div class="head-button">
              <button class="btn btn-warning px-4 py-2 d-none d-md-block mx-auto font-weight-bold" @click.stop="$router.push({path: '/compare_deals'})"
               @click="$parent.updateViewKey()">Start Comparing
              </button>
              <button class="btn btn-warning px-2 py-2 d-md-none d-block mx-auto font-weight-bold" @click.stop="$router.push({path: '/compare_deals'});"
                @click="$parent.updateViewKey()">
                compare
                <img class="ml-2" :src="require('images/svg/compare-ph.svg')" width="25">
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header',
}
</script>
