<template>
  <div class="div-minutes pt-4 compare-filters">
    <h5 class="pb-2 m-0">Contract Length <span class="fs-13">(months)</span></h5>
    <div class="w-100 row m-0 align-self-center justify-content-center">
      <div
        class="position-relative mt-2 mt-md-0 input-div3"
        v-for="(value, name) in this.contract"
        :key="name"
      >
        <input
        name="contract_length"
        class="opacity-0 contract cursor-p form-control position-absolute input-tag-radio3"
        :id="`contract-${name}`"
        type="checkbox"
        :value="name"
        v-model="checkedContract"
        v-on:change="setFilter"
        >
        <label :for="`contract-${name}`" class=" input-radio3 rounded py-2 mx-auto cursor-p">
          {{value}}
        </label>
      </div>
    </div>
  </div>
</template>
<script>
  export default{
    name: "contractFilter",
    data: function () {
      return {
        checkedContract: this.$route.query.contract_length ? this.$route.query.contract_length.split(",") : [],
        contract:{
          "0":'No Contract',
          "1-5": '1-5',
          "6-11": '6-11',
          "12-23": '12-23',
          "24-100": '24+',
        }
      }
    },
    methods: {
       setFilter(event) {
        var checkboxes = document.getElementsByName('contract_length')
        checkboxes.forEach((item) => {
            if (item !== event.target) {
              item.checked = false
            }else{
              this.checkedContract = event.target.checked ? [event.target.value] : []
            }
        })
        this.$emit('update-filter','contract_length', this.checkedContract);
      },
    }
  }
</script>
<style type="text/css" lang="scss" scoped>
  .compare-filters{

  }
</style>
