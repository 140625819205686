<template>
  <div id="header-deal">
    <div class="d-none d-md-block position-relative">
      <img :src="require('images/banner-iphone.jpg')" class="img-mobile">
    </div>
    <div class="d-md-none d-block">
      <img :src="require('images/banner-iphone-mobile.jpg')" class="img-mobile">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderDeal',
}
</script>
