<template>
  <mobile-loader/>
<!--   <div class="mobile-page pb-5">
    <div v-else>
      <div class="header-section">
        <div class="bg-light-blue py-5 mobiles-hero">
          <div class="container position-relative">
            <h2 class="font-weight-bold mb-3">Compare mobile phone deals</h2>
            <span>Start typing the model you are looking for: </span>
            <div class="bg-white position-absolute w-m-50 w-sm-100 suggestions-area">
              <div class="autosuggest-container">
                <vue-autosuggest
                  v-model="query"
                  :suggestions="filteredOptions"
                  @selected="onSelected"
                  :get-suggestion-value="getSuggestionValue"
                  @closed="onSelecteds"
                  :input-props="{id:'autosuggest__input', placeholder:'e.g. iPhone XR or Samsung', 'aria-activedescendant': 'autosuggest__results-item--0'}">
                  <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
                    <img :style="{ display: 'flex', height: '50px', marginRight: '10px'}" :src="suggestion.item.avatar" />
                    <div style="{ display: 'flex', color: 'navyblue'}">{{suggestion.item.name}}</div>
                  </div>
                </vue-autosuggest>
              </div>
              <button class="btn btn-default search-button" type="submit">
                <font-awesome-icon icon="search" />
              </button>
            </div>
          </div>
        </div>
        <div class="container py-4">
          <div class="row">
            <div class="col-md-6 cursor-p deals-option" @click="$router.push({path: `/mobiles/contract_phone_deals?`})">
              <div class="border">
                <img v-bind:src="require('images/banner.png')" width="100%"  height="auto">
                <strong>Find a mobile contract</strong>
              </div>
            </div>
            <div class="col-md-6 cursor-p text-center deals-option mt-md-0 mt-3" @click="$router.push({path: '/sim_only_deals'})">
              <div class="border">
                <img v-bind:src="require('images/sim.png')" width="78%" height="auto" class="sim">
                <strong class="bg-white">I'm happy with my phone</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="pt-3 pt-md-5 pb-5">
        <mobile-list v-if="topProducts.length == 0"/>
        <div class="container" v-else>
          <strong class="row text-left">
            Do you know which Model you want already? Click and start comparing
          </strong>
          <div>
            <div class="p-3 shadow-box">
              <agile :mobileFirst="true" :options="myOptions"
              v-if="topProducts.length" :nav-buttons="true" >
                <div class="slide cursor-p text-center bg-white agile pt-4
                my-3" v-for="product in topProducts" style="width: 207px;"
                @click="$router.push({path: `/model_deals?model_name=${product.device_name}`})">
                  <img class="my-2" :src="product.avatar"
                  style="height: 250px;">
                  <h5 class="font-weight-bol mt-2">{{product.name}}</h5>
                </div>
              </agile>
            </div>
          </div>
        </div>
        <brand-list v-if="brands.length == 0"/>
        <div class="container mt-3 mt-md-5" v-else>
          <strong class="row text-left">If you know the brand that you want, click and start comparing</strong>
          <div class="row">
            <div class="col-lg-3 col-6 text-center px-2 cursor-p mb-2" v-for="brand in brands"  v-if="['apple','samsung','google','huawei','sony','xiaomi','moto', 'oneplus'].includes(brand.name)" >
              <div class="shadow-box img-size pb-2 bg-white pt-2 h-100 d-flex align-items-center justify-content-center" @click="$router.push({path: `/mobiles/contract_phone_deals?brands=${brand.id}`})">
                <img v-bind:src="require(`images/logos/largelogo/${brand.name}.png`)" width="100px" class="brand-img" >
              </div>
            </div>
          </div>
        </div>
        <network-list v-if="networks.length == 0"/>
        <div class="container mt-3 mt-md-5" v-else>
          <strong class="row text-left">If you already know which network you want, click and start comparing</strong>
          <div class="row">
            <div class="col-lg-3 col-6 text-center px-2 cursor-p mb-2" v-for="network in networks">
              <div class="shadow-box pb-2 bg-white pt-2 h-100 d-flex align-items-center justify-content-center" @click="$router.push({path: `/mobiles/contract_phone_deals?networks=${network.id}`})">
                <img v-bind:src="require(`images/logos/largelogo/${network.name.split(' ').join('')}.png`)" height="60px" width="auto" class="network-img">
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
 </div> -->

</template>

<script>
// import {mapMutations, mapGetters} from 'vuex';
// import http from '../common/http'
// import { VueAutosuggest } from "vue-autosuggest";
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faSearch } from '@fortawesome/free-solid-svg-icons'
import MobileLoader from '../common/mobile_page_loader'
// import BrandList from '../common/brands_list'
// import MobileList from '../common/mobiles_list'
// import NetworkList from '../common/network_list'
// import { VueAgile } from 'vue-agile'
// library.add(faSearch)
export default {
  components: {
  //   VueAutosuggest,
    MobileLoader
  //   BrandList,
  //   MobileList,
  //   NetworkList,
  //   VueAgile
  },
  // data() {
  //   return {
  //     query: "",
  //     selected: "",
  //     topProducts: [],
  //     networks: [],
  //     brands: [],
  //     suggestions: [
  //       {
  //         data: []
  //       }
  //     ],
  //     myOptions: {
  //       navButtons: true,
  //       dots: false,
  //       responsive: [
  //         {
  //           breakpoint: 900,
  //           settings: {
  //             centerMode: true,
  //             slidesToShow: 4,
  //           }
  //         },
  //         {
  //           breakpoint: 768,
  //           settings: {
  //             slidesToShow: 1,
  //           }
  //         },
  //       ]
  //     }
  //   };
  // },
  // computed: {
  //   ...mapGetters([
  //     'loader'
  //   ]),
  //   filteredOptions() {
  //     return [
  //       {
  //         data: this.suggestions[0].data.filter(option => {
  //           return option.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
  //         })
  //       }
  //     ];
  //   },
  // },
  created(){
    window.location = "https://mobile-phones.switchuk.com/"
    // this.fetchMobile()
    // this.fetchTopProducts()
    // this.fetchNetworks()
    // this.fetchBrands()
  },
  // mounted(){
  //   this.setSpinner(false)
  //   this.setLoader(false)
  // },
  // methods:{
  //   ...mapMutations([
  //       'setSpinner',
  //       'setLoader'
  //   ]),
  //   fetchMobile(){
  //     http
  //       .get(`/products.json`)
  //       .then((res) => {
  //         this.suggestions[0].data = res.data
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   },
  //   fetchTopProducts(){
  //     http
  //       .get(`/top_products.json`)
  //       .then((res) => {
  //         this.topProducts = res.data
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   },
  //   fetchNetworks: function() {
  //     http.get('/networks.json')
  //       .then( res => {
  //         this.networks = res.data
  //       }).catch(err => {
  //         console.log(err)
  //       })
  //   },
  //   fetchBrands: function() {
  //     http.get('/brands.json')
  //       .then( res => {
  //         this.brands = res.data
  //       }).catch(err => {
  //         console.log(err)
  //       })
  //   },
  //   onSelected(item) {
  //     this.$router.push({path: `/model_deals?model_name=${item.item.device_name}`})
  //     this.selected = item.item;
  //   },
  //   onSelecteds(ue){
  //   },

  //   getSuggestionValue(suggestion) {
  //     // console.log(suggestion.item.name)
  //     return suggestion.item.name;
  //   },

  // }
}
</script>

<style lang="scss">
.mobile-page{
  .header-section{
    // background-image: linear-gradient(92deg, #0868b27a 0%, #dae8f5 94%);

    .mobiles-hero {
      height: 200px;
    }
  }
  .agile__nav-button:hover{
    background-color: rgba(0, 0, 0, 0.05) !important;
  }
  strong{
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: #010101;
    padding: 6px;
    margin-top: -1px;
    display: block;
  }

  input {
    padding: 0.5rem;
    width: 100%;
    border: 2px solid #0469b2;
    border-radius: 4px;
  }

  ul {
    width: 100%;
    color: rgba(30, 39, 46,1.0);
    list-style: none;
    margin: 0;
    padding: 0.5rem 0 .5rem 0;
  }
  li {
    margin: 0 0 0 0;
    padding: 0.75rem 0 0.75rem 0.75rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid;
  }
  li:hover {
    cursor: pointer;
  }

  .autosuggest__results{
    border: 1px solid;
    max-height: 300px;
    overflow: auto;
    border-radius: 5px;
    padding: 0px 10px
  }
  .autosuggest-container {
    display: flex;
    justify-content: center;
  }

  #autosuggest { width: 100%; display: block;}
  .autosuggest__results-item--highlighted {
    background-color: #eff7ff !important;
  }
  .btn-blue{
    padding: 0.5rem;
    width: 100%;
    color: white;
    font-weight: 600;
    font-size: 16px;
  }
  .company-img{
    height: 40px;
  }
  .suggestions-area{
    z-index: 2;
    width: 88%;
  }
  .search-button{
    position: absolute;
    right: 0;
    top: 0px;
    height: 43px;
    border-left: 2px solid #0868b2;
    border-radius: 0px;
  }
  @media only screen and (max-width: 768px) {
    .sim{
      height: auto;
      max-width:100%;
    }
    strong{
      font-size: 16px;
    }
    .bg-light-blue{
      .network-img{
        height: 34px;
        width: auto;
      }
      .brand-img{
        max-width: 100%;
        width: auto;
        max-height: 34px;
        padding: 0px 14px;
      }
    }
  }
    @media only screen and (max-width: 425px) {
      .search-button {
        height: 45px;
      }
      .img-size{
        height: 70px !important;
      }
    }
// VueAgile styles
  .agile {
    &__nav-button{
      background: transparent;
      border: none;
      color: #fff;
      font-size: 24px;
      height: 100%;
      position: absolute;
      top: 0;
      transition-duration: .3s;
      width: 80px;

      &:hover{
        background-color: rgba(#000, .5);
        opacity: 1;
        }
      &--prev{
        left: 0;
        color: black;
      }

      &--next{
        right: 0;
        color: black;
      }
    }
    &__dots {
      bottom: 10px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }
    &__dot{
      margin: 0 10px;

      button{
        background-color: transparent;
        border: 1px solid #fff;
        border-radius: 50%;
        cursor: pointer;
        display: block;
        height: 10px;
        font-size: 0;
        line-height: 0;
        margin: 0;
        padding: 0;
        transition-duration: .3s;
        width: 10px;
      }
      &--current,
      &:hover{
        button{
          background-color: #fff;
        }
      }
    }
  }
}
@media only screen and (max-width: 360px) {
  .slide{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

</style>
