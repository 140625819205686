<template>
  <div class="pb-5">
    <model-banner @update-products="updateProducts"/>
    <div class="container mt-2 mt-md-5 px-0">
      <sort-filter :product_type='"Phone"' @update-products="updateProducts"></sort-filter>
      <div class="row w-100 m-0">
        <filters @update-products="updateProducts" :model='true' ></filters>
        <model-deals @update-products="updateProducts"></model-deals>
      </div>
    </div>
  </div>
</template>

<script>
  import ModelBanner from '../model_page/model-banner'
  import ModelDeals from '../model_page/model_deals'
  import filters from '../filters/filters'
  import SortFilter from '../filters/sort_filter'
  import {mapMutations, mapGetters} from 'vuex';
  export default {
    name: "Model",
    components: {
      ModelDeals,
      filters,
      SortFilter,
      ModelBanner
    },
    created(){
      this.setProductsList([])
      this.setPageIndex(0)
    },
    methods:{
      ...mapMutations([
        'setProductsList',
        'setPageIndex'
      ]),
      updateProducts(filters_load = null, loaderState = null){
        if (filters_load) {
          this.componentKey += 1
        }
        this.$store.dispatch('fetchProducts', loaderState)
      },
    },
  }
</script>

