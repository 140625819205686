<template>
  <div>
    <div id="search-handset-container" class="position-relative mx-2 mb-3">
      <div class="bg-white position-absolute w-100 suggestions-area" id="search-handset">
        <div class="autosuggest-container" >
          <vue-autosuggest
            v-model="query"
            :suggestions="filteredOptions"
            @selected="onSelected"
            :get-suggestion-value="getSuggestionValue"
            @closed="onSelecteds"
            :input-props="{id:'autosuggest__input', placeholder:'e.g. iPhone 12 or Samsung', 'aria-activedescendant': 'autosuggest__results-item--0'}">
            <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
              <img :style="{ display: 'flex', height: '50px', marginRight: '10px'}" :src="suggestion.item.avatar" />
              <div style="{ display: 'flex', color: 'navyblue'}">{{suggestion.item.name}}</div>
            </div>
          </vue-autosuggest>
        </div>
        <button class="btn btn-default search-button" type="submit">
          <font-awesome-icon icon="search" />
        </button>
      </div>
    </div>
    <!-- Variations Tags Component -> It is intentionally hidden for some improvements. It will
      be made fully functional in future - Start -->
    <div class="container mb-2 d-none">
      <ul class="nav nav-pills">
        <div
          v-for="variation in variationList"
          :key="variation"
          class="position-relative"
        >
          <input
            class="position-absolute bg-white border-0
                  overflow-auto d-inline-block opacity-0 cursor-p"
            name="variants"
            type="radio"
            :value="variation"
            :id="'V'+variation"
            v-model="checkedVariant"
            @click="onVariation(variation)"
          >
          <label
            :for="'V'+variation"
            class="variation-label d-flex justify-content-start
                  px-2 align-items-center rounded-pill mr-1 cursor-p"
          >
            {{ variation }}
          </label>
        </div>
      </ul>
    </div>
    <!-- Variations Tags Component - End -->
  </div>
</template>

<script type="text/javascript">

  import {mapMutations, mapGetters} from 'vuex';
  import { VueAutosuggest } from "vue-autosuggest";
  import http from '../../common/http';
  import _ from 'lodash'

  export default{

    name: 'SearchHandset',
    components: {
      VueAutosuggest
    },

    data(){
      return{
        checkedVariant: this.$route.query.variation,
        query: "",
        selected: "",
        suggestions: [
          {
            data: []
          }
        ],
      }
    },

    created(){
      this.fetchMobile()
    },

    methods:{
      ...mapMutations([
        'setProductsList',
        'setPageIndex'
      ]),

      onSelected(item) {
        this.$router.push({path: `/mobiles/contract_phone_deals?model_name=${item.item.device_name}`})
        this.selected = item.item;
        this.setMutation();
      },

      onVariation(variation){
        this.$router.push({
              query: { ...this.$route.query, variation: variation }
          });
        this.setMutation();
      },

      setMutation(){
        this.setPageIndex(0)
        this.setProductsList([])
        this.$emit('update-products',true,null)
      },


      onSelecteds(ue){
      },

      getSuggestionValue(suggestion) {
        return suggestion.item.name;
      },

      fetchMobile(){
        http
          .get(`/products.json`)
          .then((res) => {
            this.suggestions[0].data = res.data
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },

    computed: {

      filteredOptions() {
        return [
          {
            data: this.suggestions[0].data.filter(option => {
              return option.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
            })
          }
        ];
      },
      ...mapGetters(['variationList'])
    },
  }

</script>

<style type="text/css" scoped>

  #search-handset-container{
    min-height: 42px;
  }

  .opacity-0{
    opacity:0;
  }

  .variation-label{
    background-color: rgb(219, 219, 223);
  }

  .variation-label:hover{
    background-color:#005593;
    color:white
  }

  input[type="radio"]:checked ~ label{
    background-color: #005593;
    color:white;
  }

</style>
