<template>
  <div class="bg-white p-md-5 pt-4 container">
    <div class="text-center">
      <p class="font-weight-bold h4">
        Switch Online Ltd (trading as switchuk.com)Cookie Policy
      </p>
    </div>
    <div>
      <p>
        This website uses cookies.
      </p>
      <p>
        A cookie is a small text file that is downloaded to your computer that gives you a more personalised experience when using our website. They are not harmful and are not used to identify you personally.
      </p>
      <p>
        For example, a cookie can record certain information about a visitor to a website to tailor their options for when they next visit.
      </p>
      <p>
        By continuing to use this website we at Switch Online Ltd will assume that you are happy with our cookie policy and that we can place cookies on your computer device.
      </p>
      <p>
        Most internet browsers will allow you to turn off cookies if you wish, you should refer to the Help guide of your browser to learn how to do this. But please remember that disabling or blocking cookies may impact your ability to use websites and the services that they offer.
      </p>
      <p>
        Some tools you may find useful for working with cookies:
      </p>
      <ul class="overflow-hidden">
        <li>
          Google Analytics Opt-out Browser Add-on - <a href="">https://tools.google.com/dlpage/gaoptout</a>
        </li>
        <li>
          Your Online Choices - <a href="">http://www.youronlinechoices.com/uk/</a>
        </li>
      </ul>
      <p>
        To learn more about cookies visit
      </p>
      <ul>
        <li>
          All about Cookies - <a href="">http://www.allaboutcookies.org/</a>
        </li>
      </ul>
    </div>
    <div class="bg-light">
      <p class="font-weight-bold h4">Cookies we use</p>
    </div>
    <div>
      <h5><u>Necessary Cookies</u></h5>
      <p>
        These help to make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies:
      </p>
      <table class="table table-bordered table-responsive border-dark table-sm">
        <thead class="thead-light">
          <tr>
            <th>Cookie Name:</th>
            <th>Provider</th>
            <th>Purpose</th>
            <th>Expiry</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              __cfduid
            </td>
            <td>
              .switchuk.com
            </td>
            <td>
              The cookie is used by cdn services like CloudFare to identify individual clients behind a shared IP address and apply security settings on a per-client basis. It does not correspond to any user ID in the web application and does not store any personally identifiable information.
            </td>
            <td>
              1 month
            </td>
          </tr>
          <!--  -->
          <tr>
            <td>
              _msuuid_55417zub66588
            </td>
            <td>
            switchuk.com
            </td>
            <td>
              No description
            </td>
            <td>
              1 year
            </td>
          </tr>
          <tr>
            <td>
              _phone_switcher_session
            </td>
            <td>
            switchuk.com
            </td>
            <td>
              No description
            </td>
            <td>
              session
            </td>
          </tr>
        </tbody>
      </table>
      <h5><u>Statistics Cookies</u></h5>
      <p>
       These help a website owner to understand how visitors interact with their website by collecting and reporting information anonymously:
      </p>
      <table class="table table-bordered table-responsive border-dark table-sm">
          <thead class="thead-light">
            <tr>
              <th>Cookie Name:</th>
              <th>Provider</th>
              <th>Purpose</th>
              <th>Expiry</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                _ga_NKQ846NLLN
              </td>
              <td>
                .switchuk.com
              </td>
              <td>
                No description
              </td>
              <td>
                2 years
              </td>
            </tr>
            <!--  -->
            <tr>
              <td>
                _hjid
              </td>
              <td>
                .switchuk.com
              </td>
              <td>
                This cookie is set by Hotjar. This cookie is set when the customer first lands on a page with the Hotjar script. It is used to persist the random user ID, unique to that site on the browser. This ensures that behavior in subsequent visits to the same site will be attributed to the same user ID.
              </td>
              <td>
                1year
              </td>
            </tr>
            <!--  -->
            <tr>
              <td>
                _hjIncludedInPageviewSample
              </td>
              <td>
                switchuk.com
              </td>
              <td>
               No description
              </td>
              <td>
                2 minutes
              </td>
            </tr>
            <!--  -->
            <tr>
              <td>
                _hjAbsoluteSessionInProgress
              </td>
              <td>
                .switchuk.com
              </td>
              <td>
                No description
              </td>
              <td>
                30 minutes
              </td>
            </tr>
            <!--  -->
            <tr>
              <td>
                _hjIncludedInSessionSample
              </td>
              <td>
                switchuk.com
              </td>
              <td>
               No description
              <td>
               2 minutes
              </td>
            </tr>
            <tr>
              <td>
                Rbda4DfirGSTGgdX7VLv
              </td>
              <td>
                store.three.co.uk
              </td>
              <td>
                No description
              </td>
              <td>
                10  years
              </td>
            </tr>
            <tr>
              <td>
                _mkto_trk
              </td>
              <td>
                .cloudflare.com
              </td>
              <td>
                This cookie is associated with an email marketing service provided by Marketo. This tracking cookie allows a website to link visitor behaviour to the recipient of an email marketing campaign, to measure campaign effectiveness.
              </td>
              <td>
                2 year
              </td>
            </tr>
            <tr>
              <td>
                _biz_nA
              </td>
              <td>
                .cloudflare.com
              </td>
              <td>
                This cookie is used to record website and server performance with each visit to the site.
              </td>
              <td>
                1 year
              </td>
            </tr>
            <tr>
              <td>
                _biz_pendingA
              </td>
              <td>
                .cloudflare.com
              </td>
              <td>
                This cookie is used to record website and server performance with each visit to the site. The data is aggregated and analyzed; therefore, it remains anonymous upon use.
              </td>
              <td>
                1 year
              </td>
            </tr>
            <tr>
              <td>
                _biz_uid
              </td>
              <td>
                .cloudflare.com
              </td>
              <td>
                Used to record website and server performance with each visit to the site. The data is aggregated and analyzed; therefore, it remains anonymous upon use
              </td>
              <td>
                1 year
              </td>
            </tr>
          </tbody>
      </table>
      <div class=" mt-5">
        <h5><u>Marketing Cookies</u></h5>
        <p>Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third-party advertisers.</p>
        <table class="table table-bordered table-responsive border-dark ">
          <thead class="thead-light">
            <tr>
              <th scope="col">Cookie Name</th>
              <th scope="col">Provider</th>
              <th scope="col">Purpose</th>
              <th scope="col">Expiry</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>DSID</td>
              <td>doubleclick.net</td>
              <td>Security, Functionality, Advertising</td>
              <td>2 weeks</td>
            </tr>
            <tr>
              <td>IDE</td>
              <td>doubleclick.net</td>
              <td>Advertising</td>
              <td>2 years</td>
            </tr>
            <tr>
              <td>Datr</td>
              <td>Facebook</td>
              <td>Marketing</td>
              <td>2 years</td>
            </tr>
            <tr>
              <td>Sb</td>
              <td>Facebook</td>
              <td>Marketing</td>
              <td>2 years</td>
            </tr>
            <tr>
              <td>_Secure-3PSIDCC</td>
              <td>google.com</td>
              <td>Targeting cookie. Used to create a user profile and display relevant and personalised Google Ads to the user.</td>
              <td>2 years</td>
            </tr>
            <tr>
              <td>SIDCC</td>
              <td>google.com</td>
              <td>Security cookie to confirm visitor authenticity, prevent fraudulent use of credentials, and protect visitor data from unauthorized access.</td>
              <td>3 months</td>
            </tr>
            <tr>
              <td>1P_JAR</td>
              <td>google.com</td>
              <td>Based on recent searches and previous interactions, custom ads are placed on Google websites.</td>
              <td>1 week</td>
            </tr>
            <tr>
              <td>_gcl_aw</td>
              <td>Google Ads</td>
              <td>To provide ad delivery or retargeting.</td>
              <td>90 days</td>
            </tr>
            <tr>
              <td>_fbp</td>
              <td>Facebook</td>
              <td>To store and track visits across websites.</td>
              <td>3 months</td>
            </tr>
            <tr>
              <td>_schn</td>
              <td>Snap</td>
              <td>This cookie comes from the Snapchat retargeting pixel. This pixel is used to retarget and attribute traffic coming from the social network.</td>
              <td>1 day</td>
            </tr>
            <tr>
              <td>_scid</td>
              <td>Snap</td>
              <td>Used to help identify a visitor.</td>
              <td>1 year</td>
            </tr>
            <tr>
              <td>Ads/Ga-Audiences</td>
              <td>Google Ads</td>
              <td>Used to store information for remarketing purposes.</td>
              <td>Expires immediately</td>
            </tr>
            <tr>
              <td>MUID</td>
              <td>Microsoft Clarity</td>
              <td>Used to store and track visits across websites.</td>
              <td>1 year</td>
            </tr>
            <tr>
              <td>Sc_At</td>
              <td>Snap</td>
              <td>Used to identify a visitor across multiple domains.</td>
              <td>1 year</td>
            </tr>
            <tr>
              <td>Tr</td>
              <td>Facebook</td>
              <td>Used to provide ad delivery or retargeting.</td>
              <td>3 months</td>
            </tr>
            <tr>
              <td>Fr</td>
              <td>Facebook</td>
              <td>Used to provide ad delivery or retargeting.</td>
              <td>3 months</td>
            </tr>
            <tr>
              <td>_ttp</td>
              <td>TikTok</td>
              <td>To measure and improve the performance of your advertising campaigns and to personalize the user's experience (including ads) on TikTok.</td>
              <td>13 months</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  export default{
    name: "Cookie Policy"
  }
</script>
<style type="text/css">
</style>
