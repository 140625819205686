 <template>
  <!-- deals section -->
  <div class="col-xl-9 px-2">
    <deals-loader v-if="this.productsList.length <= 0 && this.loader" />
    <div v-else>
      <div v-if="this.productsList.length > 0">
        <div v-for="product in this.productsList">
          <div class="row w-100 m-0">
            <div class="col-md-3 pl-md-0 px-0" v-if="product.package.promo">
              <p id="best-deal" class="m-0 special-deal-text">Special Deal</p>
            </div>
          </div>
          <div class="deal-div bg-white position-relative p-1 mb-4 py-md-1 pt-2 rounded-0" :class="{'special-deal' : product.package.promo }">
            <div class="row w-100 m-0 align-items-center pt-2">
              <div class="col-md-2 col-4 border-right text-center br-md-none">
                <div class="mx-auto d-sm-block d-md-block network-thumb" :class="`${product.package.name.split(' ').join('')}-img`" ></div>
              </div>
               <!-- mobile view-->
              <div class="col-8 d-md-none d-block">
                <p v-if="product.package.contract_length > 0" class="m-0 "><strong class="text-blue">{{product.package.contract_length}}</strong> Months contract</p>
                <p v-else class="m-0 "><strong> No</strong> contract</p>
                <div class="d-flex text-center d-md-none d-block w-100 py-3 border">
                  <div class="col-6 text-center align-self-center">
                    <div v-if="product.package.monthly_cost != 0">
                      <p class="m-0"><strong class="h5 font-weight-bold">£{{product.package.monthly_cost}}</strong></p>
                      <p class="m-0 font-weight-bold">Per month</p>
                    </div>
                    <div v-else>
                      <p class="m-0"><strong class="h5 font-weight-bold">£{{product.package.upfront_cost}}</strong></p>
                      <p class="m-0 font-weight-bold">Upfront Cost</p>
                    </div>
                  </div>
                  <div class="col-6 text-center align-self-center">
                    <div class="position-relative mt-2 d-inline-block">
                      <h6
                        id="tech"
                        class="bg-warning rounded-circle position-absolute fs-11 text-black"
                        v-if="product.package.technology"
                      >
                        <small class="font-weight-bold">{{product.package.technology}}</small>
                      </h6>
                      <img
                      id='sim-img'
                      v-bind:src="require(`images/sim/sim_deals/${product.package.name.split(' ').join('')}.png`)"
                      >
                    </div>
                  </div>
                </div>
              </div>
               <!-- End mobile view-->
              <div class="col-md-6 px-md-2 px-0 align-self-end">
                <div class="row w-100 m-0 py-3 py-md-0">
                  <div class="col-4 border-right text-center d-flex justify-content-center">
                     <chart-data
                        color="#28a745"
                        secondColor="#28a7466b"
                        name="Call"
                        :value="product.package.minutes == null ? product.package.max_minutes : product.package.minutes"
                        :stringValue="product.package.formatted_minutes"
                        :maxValue="product.package.max_minutes"
                    ></chart-data>
                  </div>
                  <div class="col-4 border-right text-center d-flex justify-content-center">
                    <chart-data
                        color="#ffbe07"
                        secondColor="#ffbe0752"
                        name="Data"
                         :value="product.package.data == null ? product.package.max_data : product.package.data"
                        :stringValue="product.package.formatted_data"
                        :maxValue="product.package.max_data"
                    ></chart-data>
                  </div>
                  <div class="col-4 border-right text-center br-md-none d-flex justify-content-center">
                    <div class="d-flex align-items-center justify-content-center">
                      <chart-data
                        color="#0469b2"
                        secondColor="#0469b266"
                        name="Texts"
                        :value="product.package.texts == null ? product.package.max_texts : product.package.texts"
                        :stringValue="product.package.formatted_texts"
                        :maxValue="product.package.max_texts"
                      ></chart-data>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2 text-center d-none d-md-block">
                <div v-if="product.package.contract_length > 0" class="pb-2" id="contract">
                  <h6 class="mb-0">
                    <strong>{{product.package.contract_length}}</strong>
                  </h6>
                  <span class="fs-13">Months contract</span>
                </div>
                <div v-else class="pb-2" id="contract">
                  <span class="fs-13"><strong>No</strong> contract</span>
                </div>
                <div class="position-relative mt-2 d-inline-block">
                  <h6
                    id="tech"
                    class="font-weight-bold bg-warning mx-auto rounded-circle position-absolute fs-13 text-black"
                    v-if="product.package.technology"
                  >
                    {{product.package.technology}}
                  </h6>
                  <img
                    id='sim-img'
                    v-bind:src="require(`images/sim/sim_deals/${product.package.name.split(' ').join('')}.png`)"
                  >
                </div>
              </div>
              <div class="col-md-2 text-center align-self-end py-md-2 px-md-2">
                <!-- desktop view-->
                <div v-if="product.package.monthly_cost != 0">
                  <p class="m-0 d-none d-md-block lh-1"><strong class="h5 font-weight-bold">£{{product.package.monthly_cost}}</strong>
                  </p>
                  <p class="m-0 d-none d-md-block lh-1">Per month</p>
                </div>
                <div v-else>
                  <p class="m-0 d-none d-md-block mb-2 lh-1">
                    One Time Payment
                  </p>
                  <p class="m-0 d-none d-md-block lh-1"><strong class="h5 font-weight-bold">£{{product.package.upfront_cost}}</strong>
                  </p>
                  <p class="m-0 d-none d-md-block lh-1">Upfront costs</p>
                </div>
                <button id="see-deals" class="btn btn-blue px-lg-4 px-md-2 px-5 my-md-2 mt-2 text-white w-100" @click.stop="$router.push({path: `/packages/${product.id}`})">See Deal</button>
              </div>
            </div>
            <div v-if="product.package.description" id="description">
              <vue-accordion :features='false' :description='product.package.description'/>
            </div>
          </div>
        </div>
        <infinite-loading @infinite="infiniteHandler"></infinite-loading>
      </div>
      <div v-else-if="!this.loader">
          <div class="uk-alert-danger uk-tile uk-padding-large uk-text-center" uk-alert>
            <div class="border text-center rounded p-5">
              <img id="no-prod-img" :src="require('images/no-data.png')" class="no-prod-img">
              <p>No Products found</p>
              <button
                type="button"
                class="bg-blue other-deals btn rounded-pill text-white"
                @click="removeFilters"
              >
                Explore Other Deals
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapMutations, mapGetters} from 'vuex'
  import InfiniteLoading from 'vue-infinite-loading'
  import ChartData from '../deal_details/chart_data'
  import DealsLoader from '../../common/deals_loader'
  import VueAccordion from './vue_accordion'
   export default{
    name: "Deals",
    components: {
      InfiniteLoading,
      ChartData,
      DealsLoader,
      VueAccordion
    },
    data: function(){
      return{
        products: [],
      }
    },
    mounted(){
      this.updateProducts()
    },
    computed: {
      ...mapGetters([
        'pageIndex',
        'productsList',
        'loader'
      ]),
    },
    methods:{
      ...mapMutations([
          'setPageIndex'
      ]),
      infiniteHandler($state){
        this.setPageIndex(this.pageIndex + 1)
        this.$emit('update-products',null, $state)
      },
      removeFilters() {
        let filters_load = true
        this.$router.push({path: '/sim_only_deals'})
        this.updateProducts(filters_load)
      },
      updateProducts(filters_load){
        this.$emit('update-products',filters_load,null)
      },
    },
  }
</script>

<style type="text/css" lang="scss" scoped>
  #sim-img{
    height: 65px;
  }
  #contract{
    line-height: 1;
  }
  #tech{
    height: 26px;
    width: 26px;
    top: -10px;
    right: -7px;
    line-height: 2.2;
  }
  @media only screen and (max-width: 741px) {
    #tech{
      height: 19px;
      width: 19px;
      top: -8px;
      right: -8px;
      line-height: 1.8;
    }
  }
  @media only screen and (max-width: 741px) {
    #sim-img{
      height: 50px;
    }
  }
  @media only screen and (max-width: 280px) {
    #no-prod-img{
      width: 200px;
    }
  }
</style>
