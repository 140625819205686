<template>
  <div>
    <div class="module-border-wrap">
      <div class="container px-3">
        <div class="row no-gutters" v-if="productsList.length > 0">
          <div class="col-md-6 d-flex product-show align-items-center">
            <div class="d-none d-md-block position-relative">
              <img class="thumb" :src="productsList[0].package.image1" style="height: 300px" >
            </div>
            <div class="d-md-none d-block">
              <img class="img-size thumb" :src="productsList[0].package.image1">
            </div>
            <div class="ml-3">
              <div class="mb-1 text-blue font-weight-bold fs-39">
                {{getname(productsList[0])}}
              </div>
              <div class="px-4 py-3 rounded-lg shadow">
                <div class="font-weight-bold m-0 text-blue pr-2">
                  <h4 class="font-weight-bold mb-0 mt-2 fs-24 text-center mb-2">Free</h4>
                  <div class="text-muted font-weight-bold text-center">
                    Call - Data
                  </div>
                </div>
                <div class="ml-2">
                  <dropdown :options="colors"
                            :selected="{name: selected_color()}"
                            :placeholder="'Select Color'"
                            :closeOnOutsideClick="true"
                            @updateOption="changeColor">
                  </dropdown>
                </div>
              </div>
              <div class="icons fa-cog mt-4 text-center">
                <font-awesome-icon icon="comments" class="fa-lg"/>
                <font-awesome-icon icon="phone" class= "fa-flip-horizontal ml-3 fa-lg" />
                <font-awesome-icon icon="wifi" class="ml-3 fa-lg" />
              </div>
            </div>
          </div>
          <div class="col-md-5 d-none d-md-block ml-auto shadow px-0">
            <div class="text-center bg-light py-3 mb-2">
              <h3>Top
                <span class="text-blue">{{getname(topProduct)}}</span>
                Deal
              </h3>
            </div>
            <div class="d-flex justify-content-center">
              <div>
                <img class="thumb" :src="topProduct.package.image1" style="height: 200px">
              </div>
              <div class="ml-3">
                <div>
                  <font-awesome-icon icon="plus"/>
                  <span class="ml-2 mb-1">{{topProduct.package.formatted_data}} data</span>
                </div>
                <div>
                  <font-awesome-icon icon="plus"/>
                  <span class="ml-2 mb-1">{{topProduct.package.formatted_minutes}} minutes
                  </span>
                </div>
                <div>
                  <font-awesome-icon icon="plus"/>
                  <span class="ml-2 mb-1">{{topProduct.package.formatted_texts}} texts</span>
                </div>
                <div class="mt-4">
                  <span class="ml-2">£ {{topProduct.package.upfront_cost}} upfront</span>
                </div>
                <div class="font-weight-bold">
                  <span class="ml-2">£ {{topProduct.package.monthly_cost}} monthly</span>
                </div>
                <div class="mx-auto d-none d-md-block network-logo" :class="`${topProduct.package.name.split(' ').join('')}-img`">
                </div>
              </div>
            </div>
            <div class="text-center mb-3">
              <button class="btn btn-blue my-md-2 mt-2 text-white w-75 rounded-pill"
                      @click.stop="$router.push({path: `/packages/${topProduct.id}`,
                      query: {variation_id: topProduct.package.id}})"> See Deal
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapMutations, mapGetters} from 'vuex';
  import _ from 'lodash';
  import dropdown from 'vue-dropdowns';
  import { VueAgile } from 'vue-agile'
  import http from "../../common/http";

  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faComments, faPhone, faWifi, faPlus } from '@fortawesome/free-solid-svg-icons'
  library.add(faComments, faPhone, faWifi, faPlus)

  export default {
    name: 'ModelBanner',
    components: {
      dropdown,
      VueAgile
    },
    data: function(){
      return{
        colors: [],
        topProduct: [],
      }
    },
    created(){
      this.setPageIndex(0)
    },
    mounted(){
      this.getProductsColor()
    },
    computed: {
      ...mapGetters([
        'productsList',
      ]),
    },
    methods:{
      ...mapMutations([
        'setProductsList',
        'setPageIndex'
      ]),
      getname(product){
        let model = product.name.split(' ')
        model = model[0] + ' ' + model[1]
        this.topViewpoint()
        return model
      },
      getProductsColor(){
        var query = this.$route.query
        http.get('/get_products_color.json', {params: query} )
          .then( res => {
            this.colors = res.data
          }).catch(err => {
            console.log(err)
          })
      },
      changeColor(variation) {
        this.setProductsList([])
        this.setPageIndex(0)
        this.$router.push({ query: { ...this.$route.query, color : variation.name }})
        this.$route.query.page = "product_deals"
        this.$emit('update-products')
      },
      selected_color() {
        return this.$route.query.color || this.productsList[0].package.color
      },
      topViewpoint(){
        let score = -1;
        let id = 0;

        _.forEach(this.productsList, function(product){
          if (product.package.viewpoints > score) {
            score = product.package.viewpoints
            id = product.package.aw_product_id
          }
        });
        let _this = this
        _.forEach(this.productsList, function(product){
          if (product.package.aw_product_id == id) {
            _this.topProduct = product
          }
        });
      },
    },
  }
</script>
<style type="text/css" lang="scss" scoped>

  @media only screen and (max-width: 325px){
    .img-size{
      height: 130px ;
      max-width: 55px ;
    }
  }
  .deal-color{
    background-color: #abe7fc;
  }
  .module-border-wrap {
    padding: 1rem;
    position: relative;
    border-bottom: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #025693, #cfe7fc);
  }
  .curve{
    border-radius: 16px;
  }
  .fa-cog {
    color: #005593;
  }
  .top-border{
    border-top: 1px solid white !important;
  }
</style>
