<template>
  <div class="row w-100 pb-5 m-0 align-items-center justify-content-between py-2">
    <div class="col-8 px-0">
      <h5 class="m-0">Model</h5>
      <p class="m-0 text-blue fs-13">No Phone Selected</p>
    </div>
    <div class="col-4 pr-0 text-right">
      <img
        :src="require('images/empty-phone2.png')"
        width="40" height="70" data-toggle="modal"
        data-target="#phone-model" class="selected-phone"
      >
      <!-- Modal -->
      <div class="modal fade" id="phone-model" tabindex="-1" role="dialog" aria-labelledby="phone-modelTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header shadow-sm">
              <h5 class="modal-title " id="exampleModalLongTitle">Choose Your Mobile Model</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="w-100">
                <form class="form-inline mb-3">
                  <div class="input-group mx-auto">
                    <div class="input-group-prepend">
                      <span class="input-group-text border-right-0 bg-white pr-1" id="basic-addon1"><i class="fas fa-search"></i></span>
                    </div>
                    <input class="form-control border-left-0" type="search" placeholder="Enter Mobile Model" aria-label="Search" aria-describedby="basic-addon1">
                  </div>
                </form>
              </div>
              <div class="div-mobiles row w-100 m-0 align-items-center justify-content-center">
                <div class="phone-item border">
                  <img class="model-img" src="assets/iphone11sm.png">
                  <p class="m-0 fs-13 text-blue">iPhone 11 64GB</p>
                </div>
                <div class="phone-item border">
                  <a class="div-img" href="">
                    <img class="model-img" src="assets/iphone11sm.png">
                    <p class="m-0 fs-13 text-blue">iPhone 11 64GB</p>
                  </a>
                </div>
                <div class="phone-item border">
                  <img class="model-img" src="assets/iphone11sm.png">
                  <p class="m-0 fs-13 text-blue">iPhone 11 64GB</p>
                </div>
                <div class="phone-item border">
                  <img class="model-img" src="assets/iphone11sm.png">
                  <p class="m-0 fs-13 text-blue">iPhone 11 64GB</p>
                </div>
                <div class="phone-item border">
                  <img class="model-img" src="assets/iphone11sm.png">
                  <p class="m-0 fs-13 text-blue">iPhone 11 64GB</p>
                </div>
                <div class="phone-item border">
                  <img class="model-img" src="assets/iphone11sm.png">
                  <p class="m-0 fs-13 text-blue">iPhone 11 64GB</p>
                </div>
                <div class="phone-item border">
                  <img class="model-img" src="assets/iphone11sm.png">
                  <p class="m-0 fs-13 text-blue">iPhone 11 64GB</p>
                </div>
                <div class="phone-item border">
                  <img class="model-img" src="assets/iphone11sm.png">
                  <p class="m-0 fs-13 text-blue">iPhone 11 64GB</p>
                </div>
                <div class="phone-item border">
                  <img class="model-img" src="assets/iphone11sm.png">
                  <p class="m-0 fs-13 text-blue">iPhone 11 64GB</p>
                </div>
                <div class="phone-item border">
                  <img class="model-img" src="assets/iphone11sm.png">
                  <p class="m-0 fs-13 text-blue">iPhone 11 64GB</p>
                </div>
                <div class="phone-item border">
                  <img class="model-img" src="assets/iphone11sm.png">
                  <p class="m-0 fs-13 text-blue">iPhone 11 64GB</p>
                </div>
                <div class="phone-item border">
                  <img class="model-img" src="assets/iphone11sm.png">
                  <p class="m-0 fs-13 text-blue">iPhone 11 64GB</p>
                </div>
                <div class="phone-item border">
                  <img class="model-img" src="assets/iphone11sm.png">
                  <p class="m-0 fs-13 text-blue">iPhone 11 64GB</p>
                </div>
                <div class="phone-item border">
                  <img class="model-img" src="assets/iphone11sm.png">
                  <p class="m-0 fs-13 text-blue">iPhone 11 64GB</p>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancle</button>
              <button type="button" class="btn btn-blue">Apply</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default{
    name: "ModelFilter"
  }
</script>
