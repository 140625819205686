<template>
  <mobile-loader/>
  <!-- <div class="sim_deals pb-5">
    <div class="container mt-2 mt-md-5 px-0">
      <sort-filter :product_type='"Sim"' @update-products="updateProducts">
      </sort-filter>
      <div class="row w-100 m-0">
        <sim-filters :badcredit='false' @update-products="updateProducts" :key="componentKey">
        </sim-filters>
        <sim-deals @update-products="updateProducts"></sim-deals>
      </div>
    </div>
  </div> -->
</template>

<script>
  import SortFilter from '../filters/sort_filter';
  import SimFilters from './simfilters';
  import SimDeals from './sim_deals'
  import {mapMutations, mapGetters} from 'vuex';
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
  library.add(faAngleLeft)
  export default{
    name: 'Sims',
    components: {
      SortFilter,
      SimFilters,
      SimDeals
    },
     data() {
      return {
        componentKey: 0
      }
    },
    created(){
      window.location = "https://mobile-phones.switchuk.com/"
      // this.setProductsList([])
      // this.setPageIndex(0)
    },
    methods:{
      ...mapMutations([
        'setProductsList',
        'setPageIndex',
      ]),
      // This function is called each time when the child component emits. It calls the //fetchProducts (argument-1) function of store to get the required products.
      //loaderState is initially null, it gets loaded when the user reaches the bottom of the page.Each time when the loader get loaded it fetch ten more records.
      updateProducts(filters_load,loaderState = null){
        if (filters_load) {
          this.componentKey += 1
        }
        this.$route.params.deal_type = 'sim'
        this.$store.dispatch('fetchProducts', loaderState)
      }
    },
  }
</script>
