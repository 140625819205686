<template>
  <div class="w-100 compare-filters">
    <div class="pt-4 row mx-0 w-100" id="monthly-cost-buttons">
      <h5 class="pb-2 col-12 m-0 position-relative px-1">Monthly Cost</h5>
      <div
        class="position-relative mt-2 mt-md-0 col-6 px-1"
        v-for="(value, name) in this.cost"
        :key="name"
      >
        <input
          name="cost-type"
          class="opacity-0 deals-type cursor-p form-control position-absolute input-tag-radio"
          :id="`cost-${name}`"
          type="checkbox"
          :value="name"
          :disabled="name>limit"
          :checked="monthlyCost == name"
          v-on:change="updateMonthlyCost"
        >
        <label
        :class="{'bg-light': limit<name}"
        :for="`cost-${name}`"
        class="input-radio rounded px-2 py-1 mx-auto cursor-p text-center"
        >
          <div class="w-100">
            <small class="text-muted d-block">up to</small>
            <span class="font-weight-bold">{{value}}</span>
          </div>
        </label>
      </div>
    </div>
    <div class="row mx-0 w-100">
      <div class="pt-4 col-12 px-0" id="monthly-cost-slider">
        <h5 class="m-0 pb-5 position-relative">Monthly Cost</h5>
        <vue-range-slider ref="slider"
          class= "ml-1 mr-2"
          :value="monthlyCost"
          :min="0"
          :max="limit || 250"
          :interval="2"
          :tooltip="'always'"
          :tooltip-formatter=" '£{value}'"
          @change="setFilter('monthly_cost', $event)"
          >
        </vue-range-slider>
      </div>
    </div>
    <div class="row mx-0 w-100">
      <div
        id="upfront-cost-slider"
        v-if="productType != 'sim'"
        class="col-12 px-0 pt-4 position-relative"
      >
        <h5 class="m-0 pb-5">Upfront Cost</h5>
        <vue-range-slider ref="slider2"
          class= "ml-1 mr-2"
          :value="upfrontCost"
          :min="0"
          :max="limit || 250"
          :interval="2"
          :tooltip="'always'"
          :tooltip-formatter="'£{value}'"
          @change="setFilter('upfront_cost', $event)"
          >
        </vue-range-slider>
      </div>
    </div>
  </div>
</template>
<script>
  import VueRangeSlider from 'vue-slider-component'
  import 'vue-slider-component/theme/default.css'
  import _ from "underscore"
  export default{
    name: "CostFilter",
    components: {
      VueRangeSlider
    },
    created(){
      this.limit = this.getcookie('savings_cookie');
      if(this.limit == ""){
        this.limit = 250;
      }
    },
    data() {
      return {
        cost:{
          10: "£10",
          20: "£20",
          30: "£30",
          40: "£40",
        },
        monthlyCost: this.$route.query.monthly_cost ? this.$route.query.monthly_cost : "0",
        upfrontCost: this.$route.query.upfront_cost ? this.$route.query.upfront_cost : "0",
        limit: null,
      }
    },
    props: [
     'productType'
    ],
    methods: {
      setFilter: _.debounce(
        function(filter, value) {
          this.$emit('update-filter', filter, value);
        },
        50
      ),
      updateMonthlyCost(event){
        var checkboxes = document.getElementsByName('cost-type')
        checkboxes.forEach((item) => {
          if (item !== event.target) {
            item.checked = false
          }else{
             this.monthlyCost = event.target.checked ? [event.target.value] : 0
          }
        })
        let costFilter = this.monthlyCost == 0 ? [] : this.monthlyCost
        this.$emit('update-filter', 'monthly_cost', costFilter);
      },
      getcookie(cname){
        var name = cname + "=";
        var allCookies=decodeURIComponent(document.cookie).split(';')
        for(var i = 0; i <allCookies.length; i++) {
          var c = allCookies[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }
    }
  }
</script>
<style type="text/css" lang="scss" scoped>
  .compare-filters{
    .input-radio3{
      background : #eff7ff !important;
    }
  }
</style>
