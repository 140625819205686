export default class {
  static remove(element) {
    element.classList.add();
    UIkit.util
      .animate(element, "uk-animation-fade uk-animation-reverse")
      .then(() => element.parentNode.removeChild(element));
  }

  static removeImmidiate(element) {
    element.parentNode.removeChild(element);
  }

  // Todo: create a prepend method

  static move(element, to) {
    to.appendChild(element);
  }

  static insertBefore(html, referenceNode) {
    let temp = document.createElement("div");
    temp.innerHTML = html;
    return referenceNode.parentNode.insertBefore(
      temp.firstChild,
      referenceNode
    );
  }

  static append(html, container) {
    UIkit.util.append(container, html);
  }

  static show(element) {
    element.removeAttribute("hidden");
  }

  static hide(element) {
    element.setAttribute("hidden", true);
  }

  static html(element, html) {
    element.innerHTML = html;
  }

  static addClass(element, className) {
    element.classList.add(className);
  }
  static toggleClass(element, className) {
    element.classList.toggle(className);
  }

  static removeClass(element, className) {
    element.classList.remove(className);
  }

  static hasClass(element, className) {
    return element.classList.contains(className);
  }

  static enable(element) {
    this.removeClass(element, "uk-disabled");
  }

  static disable(element) {
    this.addClass(element, "uk-disabled");
  }

  static find(selector, scope = void 0) {
    return UIkit.util.find(selector, scope);
  }

  static elements(selector) {
    return document.querySelectorAll(selector);
  }

  static element(selector) {
    return document.querySelector(selector);
  }

  static check(element) {
    element.checked = true;
  }

  static uncheck(element) {
    element.checked = false;
  }

  static replace(element, html) {
    let temp = document.createElement("div");
    temp.innerHTML = html;

    element.parentNode.replaceChild(temp.firstChild, element);
  }
}
