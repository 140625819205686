<template>
  <div id="comparefilters" class="py-4">
    <div class="container">
      <div class="filter-components">
        <h3 class="pb-3 mb-0 font-weight-bold">
          Compare Latest Handsets Deals Without any Hassle
        </h3>
        <div
          class="row p-0 mx-0 w-100 border bg-white shadow rounded d-flex py-2"
          id="compare-page-filters"
        >
          <div class="col-lg-6 col-12 border-right d-flex">
            <div class="w-100 row align-items-end p-0 mx-0">
              <div
                v-for="(model, i) in {1: 'model_one', 2: 'model_two'}"
                class="col-6 col-lg-12 p-1"
                :key="i"
              >
                <SearchDeal
                  :count="i"
                  :productName="Object.keys(packageList)[i-1]"
                  :defaultValue="setDefaultValue(model)"
                  :model='model'
                  :allProducts="products"
                  :options="options"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-12 border-right">
            <div class="w-100">
              <cost-type @update-filter="updateFilter"></cost-type>
              <data-type @update-filter="updateFilter"></data-type>
            </div>
          </div>
          <div class="col-lg-3 col-12 d-flex">
            <div class="w-100">
              <contract-type @update-filter="updateFilter"></contract-type>
            </div>
          </div>
          <div class="col-12 px-0 mt-2">
            <div class="pt-3 pb-1 px-4 border-top d-flex justify-content-lg-between
              justify-content-center align-items-center filters-footer"
            >
              <div class="text-center d-lg-block d-none">
                <img class="m-2" :src="require('images/logos/largelogo/vodafone.png')" height="22" >
                <img class="m-2" :src="require('images/logos/largelogo/virginmobile.png')" height="22" >
                <img class="m-2" :src="require('images/logos/o2.png')" height="22">
                <img class="m-2" :src="require('images/logos/giffgaff.png')" height="22">
                <img class="m-2" :src="require('images/logos/largelogo/ee.png')" height="22">
                <img class="m-2" :src="require('images/logos/3.png')" height="22">
                <img class="m-2" :src="require('images/logos/smarty.png')" height="22" >
                <img class="m-2" :src="require('images/logos/id.png')" height="22" >
                <img class="m-2" :src="require('images/logos/voxi.png')" height="22">
                <img class="m-2" :src="require('images/logos/bt.png')" height="22">
                <img class="m-2" :src="require('images/logos/sky.png')" height="22">
              </div>
              <button class="btn btn-blue text-white filter-button" @click="hideFilter">
                Show Results
              </button>
              <button
                type="button"
                class="btn btn-blue text-white modal-button d-none"
                data-dismiss="modal"
                @click="showResults">Show Results
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import SearchDeal from '../compare_deals/search_deal'
  import CostType from '../filters/cost_type'
  import DataType from '../filters/data'
  import ContractType from '../filters/contract'
  import {mapGetters} from 'vuex'
  import _find from 'lodash';

  export default{
    name: 'compare-filters',
    props: ['products','options'],
    components:{
      SearchDeal,
      CostType,
      ContractType,
      DataType,
    },
    data() {
      return{
        selectedPhoneName: {model_one:"",model_two:""}
      }
    },
    computed:{
      ...mapGetters([
        'packageList'
      ]),
    },
    methods:{
      updateFilter(filter, value){
        let filters_load = true
        this.addRoute(filter, value)
        this.$emit('update-products',filters_load)
      },
      addRoute: function(filter, value){
        eval(`this.$router.push({ query: { ...this.$route.query, ${filter} : "${value}" }})`)
      },
      showResults(){
        var show = true
        this.$emit('show-data', show)
        this.$emit('update-products')
      },

      /* Method for displaying the selected phones upon page refresh - start */
      setDefaultValue(model){
        if(this.$route.query["model_one"] || this.$route.query["model_two"]){
          var _this = this
          var result = _.find(_this.options, function(value){
            var check = value.indexOf(_this.$route.query[model]);
            if( check > -1){
              return value;
            }
          });
          if(result){
            _this.selectedPhoneName[model] = result
            /* The line below emits the phone name to "comparing component" to display
            in the buttons */
            this.$emit('phone-name', _this.selectedPhoneName)
            return result
          }
        }
      },
      /* Method for displaying the selected phones upon page refresh - end */

      hideFilter() {
        document.addEventListener("DOMNodeInserted", function () {
          var stickyTabs = document.getElementsByClassName("sticky-tabs");
          if (stickyTabs.length > 0) {
            stickyTabs[0].classList.add("active");
          }
          var models = document.getElementsByClassName("selected-models");
          if (models.length > 0) {
            _.forEach(models,function(model){
              model.classList.remove("active-model");
              model.classList.remove("shadow-custom");
            })
            models[0].classList.add("active-model");
            models[0].classList.add("shadow-custom");
          }
        });
        document.getElementById('comparefilters').classList.add("d-none")
        this.showResults()
      },
    }
  }
</script>

<style type="text/css" lang="scss" scoped>
  .modal-dialog{
    #comparefilters{
      .container{
        padding: 0!important;
      }
      padding:0!important;
      .filter-components{
        #compare-page-filters{
          border-radius: 0 0 0.25rem 0.25rem !important;
          border-top: 0 !important;
          box-shadow: none !important;
          .filter-button{
            display : none;
          }
          .modal-button{
            display : block !important;
          }
          @media screen and (max-width:575px){
            .filters-footer{
              button{
                width:100%;
              }
            }
          }
        }
        h3{
          border: 1px solid #dee2e6 !important;
          border-radius: 0.25rem 0.25rem 0 0 !important;
          padding: 1rem !important;
          background:#fff;
        }
      }
    }
  }
  @media screen and (max-width:575px){
    .filters-footer{
      button{
        width:100%;
      }
    }
  }
</style>
