<template >
  <div class="container product-show">
    <div class="row pt-md-5 pt-3 justify-content-center">
      <div class="col-lg-3 d-none d-md-block text-center mb-2">
        <img
          v-bind:src="require(`images/sim/${product.network.name.split(' ').join('')}.png`)"
          height="250px"
        >
      </div>
      <div class="col-lg-3 d-md-none text-center mb-2 py-3">
        <img v-bind:src="require(`images/logos/largelogo/${product.network.name.split(' ').join('')}.png`)" height="100px">
      </div>
      <div class="col-lg-9">
        <div class="card p-3 shadow-box product-box">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="text-capitalize text-secondary font-weight-bold mb-0"> {{product.brand}}
              </h6>
              <p class="mb-0">
                <strong>{{product.package.description}}</strong>
              </p>
              <p v-if="product.package.contract_length>0" class="m-0 "><strong  class="text-blue">{{product.package.contract_length}}</strong>  Months contract
              </p>
              <p v-else class="m-0 ">
                <strong class="text-blue">No</strong> contract
              </p>
            </div>
            <div class="col-md text-md-right">
              <a @click="setSpin" class="btn px-5 w-100 text-white font-weight-bold py-3 btn-blue" :href="product.package_variation.url">
                <span class="spinner mr-2 spinner-border spinner-border-sm invisible" role="status" aria-hidden="true"></span>
                Continue
              </a>
            </div>
          </div>
        </div>
        <div class="card pt-0 p-md-3 mt-2">
          <div class="row no-gutters pt-3">
            <div class="col-12">
              <div class="row w-100 m-0">
                <div class="col-md-3 d-none d-md-block border-right text-center">
                  <img
                    class="obj-fit-contain"
                    :src="require(`images/logos/largelogo/${product.network.name.split(' ').join('')}.png`)"
                    width="100%" height="100%"
                  >
                </div>
                <div class="col-4 col-md-3 border-right d-flex justify-content-center text-center">
                  <div class="d-flex align-items-center justify-content-center">
                    <chart-data
                      color="#28a745"
                      secondColor="#28a7466b"
                      name="Call"
                      :value="product.package.minutes == null ? product.package.max_minutes : product.package.minutes"
                      :stringValue="product.package.formatted_minutes"
                      :maxValue="product.package.max_minutes"
                    ></chart-data>
                  </div>
                </div>
                <div class="col-4 col-md-3 border-right d-flex justify-content-center text-center">
                  <div class="d-flex align-items-center justify-content-center">
                    <chart-data
                      color="#ffbe07"
                      secondColor="#ffbe0752"
                      name="Data"
                      :value="product.package.data == null ? product.package.max_data : product.package.data"
                      :stringValue="product.package.formatted_data"
                      :maxValue="product.package.max_data"
                    ></chart-data>
                  </div>
                </div>
                <div class="col-4 col-md-3 d-flex justify-content-center text-center br-md-none">
                  <div class="d-flex align-items-center justify-content-center">
                    <chart-data
                      color="#0469b2"
                      secondColor="#0469b266"
                      name="Texts"
                      :value="product.package.texts == null ? product.package.max_texts : product.package.texts"
                      :stringValue="product.package.formatted_texts"
                      :maxValue="product.package.max_texts"
                    ></chart-data>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3 px-2 px-md-0 no-gutters">
            <div class="col-6 text-center px-1">
              <div class="border p-md-4 p-2 rounded">
                <h2 class="font-weight-bold mb-0">
                  £{{product.package.monthly_cost}}
                </h2>
                <small>Monthly Cost</small>
              </div>
            </div>
            <div class="col-6 text-center px-1">
              <div class="border p-md-4 p-2 rounded">
                <h2 class="font-weight-bold mb-0">
                  £{{product.package.total_cost}}
                </h2>
                <small>Total Cost</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import _ from "underscore"
import {mapMutations, mapGetters} from 'vuex';
import ChartData from '../../deal_details/chart_data';

export default {
  name: 'ShowProduct',
  components: {
    ChartData,
  },
  data() {
    return{
      arrayOfObjects: [{name: "sadas"},{name: "assa"}]
    }
  },
  props: [
    'product'
  ],
  methods:{
    setSpin(event){
      event.currentTarget.querySelector('.spinner').classList.remove('invisible');
    }
  }
}
</script>
<style lang="scss" scoped>
  #tech{
    height: 16px;
    width: 16px;
    top: -10px;
    right: 2px;
    line-height: 1.4;
  }
  .spinner {
    margin-bottom: 0.125rem;
    margin-left: -1.5rem !important;
  }
</style>

