<template>
  <div class="div-networks pt-4">
    <h5 class="pb-2 m-0">Network</h5>
    <div v-if="loaded" class="w-100 row m-0 align-self-center justify-content-center">
      <div
        id="networks"
        class="position-relative mt-2 mt-md-0 input-div2"
        v-for="network in this.networks"
        :key="network.id"
        :class="{ 'disabled-btn' : creditCheck(network) }"
      >
        <input
        name="network"
        class="opacity-0 network cursor-p form-control position-absolute input-tag-radio2"
        :id="`network-${network.name.split(' ').join('')}`"
        type="checkbox"
        :value="network.id"
        v-model="checkedNetwork"
        v-on:change="setFilter"
        >
        <label :for="`brand-${network.name.split(' ').join('')}`" :class="`${network.name.split(' ').join('')}-img bg-img-position input-radio2 rounded py-2 mx-auto cursor-p`">
        </label>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from "underscore";
  import http from '../../common/http';
  export default{
    name: "NetworkFilter",
    data: function(){
      return{
        loaded: false,
        networks: [],
        filteredNetworkIds: [],
        checkedNetwork: this.$route.query.networks ? this.$route.query.networks.split(",") : [],
        allowedNetworks: ['smarty','giffgaff', 'voxi', 'lebara']
      }
    },
    // Run when this vue is loaded and call getNetworks function to show all the networks available in our site.
    created(){
      this.getNetworks()
    },
    // Parent component is sending these props so that we could get networks and could get our result accordingly.
    props: [
     'badcredit',
     'productType'
    ],
    methods:{
      setFilter() {
        this.$emit('update-filter','networks', this.checkedNetwork);
      },
      // This function is called to send the request to network controller to show all the networks available.Networks are fetched according to the type of product.After getching the networks, it checks either the prop badcredit is true of false, if false it filters the networks and thatdoes not entertain badcredit customers.
      getNetworks() {
        http.get('/networks.json', { params: { type: this.productType,
        model_name: this.$router.currentRoute.query.model_name } } )
          .then( res => {
            this.networks = res.data
            this.filteredNetworkIds =
              _.filter( this.networks, n => {
                 return this.allowedNetworks.includes(n.name) && this.badcredit
              })
            this.$emit('load', _.map(this.filteredNetworkIds, n => { return n.id } ))
          }).catch(err => {
            console.log(err)
          }).finally(() => {
            this.loaded = true
          })
      },
      // to disable the networks filter whose name is not present in the list of allowed networks.
      creditCheck(network) {
        return !this.allowedNetworks.includes(`${network.name}`) && this.badcredit
      }
    }
  }
</script>
<style scoped>
  .disabled-btn {
    opacity: 0.4;
    pointer-events: none;
  }
</style>
