<template>
  <div class="cursor-p" id="description" @click="toggleFeatures"  >
    <div v-show-slide="featuresOpen" class="py-2 px-4 mt-2">
      <p class="m-0 text-color">{{description}}</p>
    </div>
    <div class="row justify-content-center">
      <div v-if='featuresOpen' class="toggle-features text-center">
        <div><font-awesome-icon icon="angle-up" /></div>
        <div class="text-muted fs-11">hide</div>
      </div>
      <div v-else class="toggle-features text-center">
        <div class="text-muted fs-11">deal info</div>
        <div><font-awesome-icon icon="angle-down" /></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons'
  library.add(faAngleUp, faAngleDown)

   export default{
    name: "vue_accordion",
     props: [
       'features',
       'description'
     ],
    data: function(){
      return{
        featuresOpen: this.features
      }
    },
    methods:{
      toggleFeatures () {
        this.featuresOpen = !this.featuresOpen
      }
    },
  }
</script>
<style type="text/css" scoped>
  .text-color{
    color: black;
  }
</style>

